import { type Accessor, createSignal } from "solid-js";

export const GridLoader = () => {
	return <div>mocked GridLoader</div>;
};
export const BarLoader = () => {
	return <div>mocked bar loader</div>;
};
export const useHovering = (
	onHover?: () => void,
	onHoverLeave?: () => void,
): {
	hovering: Accessor<boolean>;

	hoveringProps: {
		onMouseEnter: () => void;
		onMouseLeave: () => void;
		onMouseMove: (e: MouseEvent) => void;
	};
	hoveringRef: (x: HTMLDivElement) => void;
} => {
	const [hovering, setHovering] = createSignal(false);

	return {
		hovering,
		hoveringRef: (_x: HTMLDivElement) => {
			// setTimeout(() => {
			// 	// if (x?.matches(":hover")) {
			// 	// 	onHover?.();
			// 	// 	setHovering(true);
			// 	// }
			// }, 0);
		},
		hoveringProps: {
			onMouseMove: () => {
				const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;
				if (isTouchDevice) {
					return;
				}
				if (!hovering()) {
					onHover?.();
					setHovering(true);
				}
			},
			onMouseEnter: () => {},
			onMouseLeave: () => {
				const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;
				if (isTouchDevice) {
					return;
				}
				onHoverLeave?.();
				setHovering(false);
			},
		},
	};
};
