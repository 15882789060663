import { filter, isEmpty, isNil } from "lodash-es";
import { Show, createMemo } from "solid-js";
import type { BookmarkDTO } from "~/rspc";
import type { Bookmark } from "~/types/Bookmark";
import type { EnrichedComponent } from "~/types/View";
import { BROWSING_STATE, REPERTOIRE_STATE, UI, USER_STATE, quick } from "~/utils/app_state";
import { START_EPD } from "~/utils/chess";
import { clsx } from "~/utils/classes";
import { registerViewMode } from "~/utils/register_view_mode";
import { useViewCoursesAction } from "~/utils/sharedActions";
import { trackEvent } from "~/utils/trackEvent";
import { CMText } from "./CMText";
import { RepertoireBuilder } from "./RepertoireBuilder";
import {
	type SidebarAction,
	SidebarActions,
	SidebarSectionHeader,
	useBiggestGapActions,
} from "./SidebarActions";
import { ChooseImportSourceOnboarding } from "./SidebarOnboarding";
import { SidebarTable } from "./SidebarTable";
import { SidebarTemplate } from "./SidebarTemplate";
import { Spacer } from "./Space";
import { Line } from "~/types/Line";

export const PreAdd: EnrichedComponent<{ bogus: any }> = (_props) => {
	const _repertoire = () => BROWSING_STATE().getActiveRepertoire()!;
	const side = () => BROWSING_STATE().activeSide;
	const activeRepertoireId = () => BROWSING_STATE().activeRepertoireId!;
	const biggestMiss = () => REPERTOIRE_STATE().repertoireGrades[activeRepertoireId()!]?.biggestMiss;
	const numMoves = () => REPERTOIRE_STATE().numMyMoves?.[activeRepertoireId()!] ?? 0;
	const empty = () => numMoves() === 0;
	const bookmarks = () => {
		if (!USER_STATE().flagEnabled("bookmarks")) {
			return [];
		}
		let bookmarks = REPERTOIRE_STATE().autoBookmarks[activeRepertoireId()!];
		if (!bookmarks || isEmpty(bookmarks)) {
			return [];
		}
		return filter(bookmarks, (b) => b.repertoireId === activeRepertoireId()!);
	};
	const actions = createMemo(() => {
		let actions: SidebarAction[] = [];
		const missActions = useBiggestGapActions();
		actions = [
			...actions,
			...missActions.map((a) => ({ ...a, style: "primary" as SidebarAction["style"] })),
		];
		actions.push({
			onPress: () => {
				quick((_s) => {
					UI().pushView(RepertoireBuilder);
					BROWSING_STATE().startBrowsing(activeRepertoireId()!, "build");
					if (empty()) {
						trackEvent("side_overview.start_building");
					} else {
						trackEvent("side_overview.keep_building");
					}
					return;
				});
			},
			// right: pluralize(numMoves(), "move"),
			text: (
				<CMText>
					{empty()
						? "Start building your repertoire"
						: isNil(biggestMiss())
							? "Browse / add new moves"
							: "Browse / edit something else"}
				</CMText>
			),
			style: "primary",
		});
		actions.push({
			text: "Import",
			onPress: () => {
				quick((_s) => {
					trackEvent("side_overview.import");
					UI().pushView(ChooseImportSourceOnboarding, {
						props: { side: side() },
					});
				});
			},
			style: "secondary" as const,
		});
		const viewCoursesAction = useViewCoursesAction({
			epd: START_EPD,
			repertoireId: BROWSING_STATE().activeRepertoireId!,
		})();
		if (viewCoursesAction && !empty()) {
			actions.push({ ...viewCoursesAction, style: "secondary" });
		}
		return actions;
	});
	return (
		<SidebarTemplate
			header={empty() ? "Start building your repertoire" : "Build your repertoire"}
			actions={[]}
			bodyPadding={false}
		>
			<SidebarActions actions={actions()} />
			<Spacer between={["actions", "actions"]} />
			<Show when={!isEmpty(bookmarks())}>
				<SidebarSectionHeader text="Bookmarks" />
				<SidebarTable
					rows={bookmarks()}
					hideAllLabels
					onClick={(bookmark: BookmarkDTO) => {
						UI().pushView(RepertoireBuilder);
						BROWSING_STATE().startBrowsing(activeRepertoireId()!, "build", {
							lineToPlay: bookmark.line,
						});
					}}
					maxRows={null}
					leftColumns={[
						{
							label: "Chapter name",
							labelStyle: "hidden",
							render: (bookmark: Bookmark) => {
								return (
									<div class="flex flex-col gap-3 items-start w-full">
										<div
											class={clsx(
												"flex flex-row justify-between w-full",
												bookmark.ecoCode.fullName.includes(":") && "pl-4",
											)}
										>
											<div class="flex flex-col items-start">
												<p class={clsx(`text-primary font-medium`)}>
													{bookmark.ecoCode.fullName.includes(":")
														? `${bookmark.ecoCode.fullName}`
														: bookmark.ecoCode.fullName}
												</p>
												<p class="text-xs font-normal text-secondary">
													{Line.toPgn(bookmark.line)}
												</p>
											</div>
											<p class={clsx(`text-xs text-secondary`)}>
												{bookmark.numMovesFromHere} moves
											</p>
										</div>
									</div>
								);
							},
							width: "auto",
						},
					]}
					rightColumns={[]}
				/>
			</Show>
		</SidebarTemplate>
	);
};

registerViewMode(PreAdd, "build");
