import { isNil } from "lodash-es";
import { type StockfishEval, formatStockfishEval } from "~/types/StockfishEval";
import { assertNotNull } from "~/utils/assert";
import { clsx } from "~/utils/classes";
import { pluralize } from "~/utils/pluralize";
import { c, stylex } from "~/utils/styles";
import { CMText } from "./CMText";
import { initTooltip } from "./Tooltip";

export const StockfishEvalPreview = (props: {
	stockfish: StockfishEval;
	faded?: boolean;
	thinking?: boolean;
}) => {
	const stockfishEval = () => props.stockfish.eval;
	const mate = () => props.stockfish?.mate;
	const whiteWinning = () =>
		(!isNil(stockfishEval()) && stockfishEval()! >= 0) ||
		(!isNil(mate()) && mate()![1] === "white");
	const backgroundSide = () => (whiteWinning() ? "white" : "black");
	const formattedEval = () => formatStockfishEval(props.stockfish!);
	return (
		<div
			class={clsx(
				"row px-1 min-w-[30px] h-[18px]  items-center justify-center rounded-sm transition-opacity tabular-nums",
				// props.thinking ? "animate-pulse" : "",
			)}
			style={stylex(c.bg(whiteWinning() ? c.gray[90] : c.gray[4]))}
			ref={(ref) => {
				initTooltip({
					ref,
					content: () => {
						assertNotNull(props.stockfish, "stockfish is null");
						if (props.stockfish.mate?.[0] === 0) {
							return "Checkmate.";
						}
						if (formattedEval() === "=") {
							return (
								<p>
									After this move, the computer evaluates the position as <b>equal</b>
								</p>
							);
						}
						if (props.stockfish?.mate) {
							const mateMoves = props.stockfish?.mate?.[0];
							const side = mateMoves > 0 ? "white" : "black";
							return `This position is a forced mate in ${pluralize(
								mateMoves,
								"move",
							)} for ${side}`;
						}
						if (props.stockfish?.eval) {
							const betterSide = whiteWinning() ? "white" : "black";
							return (
								<p>
									The computer evaluates this move as <b>better for {betterSide}</b> by the
									equivalent of <b>{formattedEval().replace(/[-+]/, "")} </b>
									pawns
								</p>
							);
						}
					},
					maxWidth: 200,
				});
			}}
		>
			<CMText
				style={stylex(c.weightHeavy, c.fontSize(10))}
				class={clsx(
					"transition-opacity",
					backgroundSide() === "white" ? "text-gray-10" : "text-gray-90",
					props.faded ? "opacity-80" : "opacity-100",
				)}
			>
				{formattedEval()}
			</CMText>
		</div>
	);
};
