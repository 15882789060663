import { isDevelopment } from "./env";

const debug_epds: string[] = [
	// "r1bqkbnr/pppppppp/2n5/8/4P3/8/PPPP1PPP/RNBQKBNR w KQkq -",
	// "rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR b KQkq -",
];

export const shouldDebugEpd = (epd: string) => {
	return debug_epds.includes(epd);
};

export const MOCK_ANNOTATIONS = false;

export const SHOULD_DEBUG_STATE_UPDATES = isDevelopment && false;

export const SKIP_MOVE_REVIEW_SERVER_UPDATES = (() => {
	if (isDevelopment) {
		return false;
	}
	return false;
})();
