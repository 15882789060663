import { createForm } from "@felte/solid";
import { shuffle } from "lodash-es";
import { onMount } from "solid-js";
import { UI } from "~/utils/app_state";
import { rspcClient } from "~/utils/rspc_client";
import { c, stylex } from "~/utils/styles";
import { trackEvent } from "~/utils/trackEvent";
import { identify } from "~/utils/user_properties";
import { CMText } from "./CMText";
import { OnboardingComplete } from "./SidebarOnboarding";
import { SidebarSelectOneOf } from "./SidebarSelectOneOf";
import { SidebarTemplate } from "./SidebarTemplate";
import { TextInput } from "./TextInput";

type Form = {
	extra: string;
	source: string;
};

export const AskAboutUserSource = () => {
	let choices = [
		"YouTube",
		"Reddit",
		"A friend",
		"Lichess",
		"X / Twitter",
		"Instagram",
		"Google",
		"App Store / Play Store",
	];
	choices = shuffle(choices);
	choices.push("Other");
	onMount(() => {
		trackEvent("onboarding.ask_about_user_source.shown");
	});
	const onSubmit = () => {
		rspcClient
			.query(["users.setUserSource", { extra: data().extra, source: data().source }])
			.then(() => {
				console.debug("Set user source!");
			});
		trackEvent(`onboarding.ask_about_user_source.continue`, { user_source: data().source });
		UI().replaceView(OnboardingComplete);
	};
	const { data, setFields } = createForm<Form>({
		initialValues: { extra: "", source: "" },
	});
	return (
		<SidebarTemplate
			header="How did you find us?"
			bodyPadding={false}
			actions={[
				{
					onPress: onSubmit,
					text: "Continue",
					style: "primary",
				},
				{
					onPress: onSubmit,
					text: "Skip this step for now",
					style: "primary",
				},
			]}
		>
			<SidebarSelectOneOf
				choices={choices}
				activeChoice={data().source}
				onSelect={(option: string) => {
					identify({
						attribution_source: option,
					});
					setFields("source", option);
				}}
				renderChoice={(option) => {
					return (
						<div style={stylex(c.row)}>
							<CMText style={stylex(c.weightSemiBold, c.fontSize(14))}>{option}</CMText>
						</div>
					);
				}}
			/>
			<div
				class="data-[open]:h-auto h-0 padding-sidebar overflow-hidde transition-all pt-8"
				data-open={data().source === "Other"}
			>
				<TextInput
					name="extra"
					placeholder={"Anything else you want to add?"}
					setFields={setFields}
				/>
			</div>
		</SidebarTemplate>
	);
};
