import { Chess } from "@lubert/chess.ts";
import type { Move, Square } from "@lubert/chess.ts/dist/types";

import { cloneDeep, forEach, isNil, noop, reverse, shuffle, sortBy } from "lodash-es";

import { animateSidebar } from "~/components/SidebarContainer";

import type { Side } from "~/types/Side";
import { SpacedRepetitionStatus } from "~/types/SpacedRepetition";
import type { Uuid } from "~/types/Uuid";

import { trackEvent } from "~/utils/trackEvent";
import { APP_STATE, type AppState, UI } from "./app_state";
import { quick } from "./app_state";

import {
	type ChessboardInterface,
	type ChessboardMoveFeedback,
	createChessboardInterface,
} from "./chessboard_interface";
import {} from "./frontend_settings";

import { MultiCallback } from "./multi_callback";

import {} from "./plans";
import { logProxy } from "./state";

import { CardReview } from "~/components/CardReview";
import type { ChessCardDTO } from "~/rspc";
import { ChessCard } from "~/types/ChessCard";
import { Epd } from "~/types/Epd";
import { MoveRating } from "~/types/MoveRating";
import { devAssert } from "./assert";
import { createChessProxy } from "./chess_proxy";
import type { CardsState } from "./chesscards_state";
import { isDevelopment } from "./env";
import { rspcClient } from "./rspc_client";
import { sleep } from "./sleep";
import { todo } from "./todo";

type ReviewResults = {
	id: string;
	result: "correct" | "incorrect";
};

type QuizPositionOutcome = "correct" | "incorrect";

export type CardReviewState = ReturnType<typeof getInitialCardReviewState>;

type Stack = [CardReviewState, CardsState, AppState];
type ReviewStats = {
	due: number;
	correct: number;
	incorrect: number;
};

type ReviewFilter =
	| "difficult-due"
	| "all"
	| "recommended"
	| "due"
	| "early"
	| "difficult"
	| "new"
	| ((c: ChessCardDTO) => boolean);

interface ReviewOptions {
	filter?: ReviewFilter;
}

const FRESH_REVIEW_STATS = {
	due: 0,
	correct: 0,
	incorrect: 0,
} as ReviewStats;

export enum QuizPositionStage {
	Start = 0,
	Hints = 1,
	Arrows = 2,
	Full = 3,
}

export type CardQuizPosition = { card: ChessCardDTO };

export const getInitialCardReviewState = () => {
	const set = <T,>(fn: (stack: Stack) => T, _id?: string) => {
		return quick((s) => {
			return fn([s.cardsState.reviewState, s.cardsState, s]);
		});
	};
	const get = <T,>(fn: (stack: Stack) => T) => {
		return fn([APP_STATE().cardsState.reviewState, APP_STATE().cardsState, APP_STATE()]);
	};
	const initialState = {
		originalQueue: null as CardQuizPosition[] | null,
		viewingLastQuizPosition: false,
		allReviewPositions: {} as Record<
			Uuid,
			{
				failed: boolean;
				reviewed: boolean;
			}
		>,
		quizPositionStage: QuizPositionStage.Start,
		chessboard: undefined as unknown as ChessboardInterface,
		reviewStats: cloneDeep(FRESH_REVIEW_STATS),
		reviewSide: "white" as Side,
		activeOptions: null as ReviewOptions | null,
		activeQueue: [] as CardQuizPosition[],
		onSessionEnd: new MultiCallback(),
		currentQuizPosition: null as CardQuizPosition | null,
		incorrectGuessesThisQuizPosition: 0,
		previousQuizPosition: null as CardQuizPosition | null,
		markCardReviewed: (result: ReviewResults) => {
			rspcClient
				.query(["cards.reviewCard", { cardId: result.id, correct: result.result === "correct" }])
				.then((srs) => {
					set(([_s, rs]) => {
						rs.cards?.forEach((card) => {
							if (card.id === result.id) {
								card.srs = srs;
							}
						});
					});
				});
		},
		resumeReview: () => {
			set(([_s, _rs, _gs]) => {
				UI().ensureView(CardReview);
			});
		},
		onPositionUpdate: () => {
			set(([_s, _rs]) => {});
		},
		startReview: (options: ReviewOptions) =>
			set(([s, _rs, _gs]) => {
				s.reviewStats = cloneDeep(FRESH_REVIEW_STATS);
				s.currentQuizPosition = null;
				animateSidebar("right");
				s.activeOptions = options ?? null;
				s.updateQueue(options);

				s.allReviewPositions = {};
				s.activeQueue.forEach((m) => {
					s.allReviewPositions[m.card.id] = {
						failed: false,
						reviewed: false,
					};
				});
				s.originalQueue = cloneDeep(s.activeQueue);
				s.setupNextQuizPosition();
				UI().pushView(CardReview);
			}),
		backToLastQuizPosition: () =>
			set(([s]) => {
				s.viewingLastQuizPosition = true;
				s.activeQueue.unshift(s.currentQuizPosition!);
				s.currentQuizPosition = s.previousQuizPosition;
				s.previousQuizPosition = null;
				s.setupCurrentQuizPosition();
				s.playCorrectMove();
				s.quizPositionStage = QuizPositionStage.Full;
			}),
		setupNextQuizPosition: () =>
			set(([s, rs, _gs]) => {
				s.previousQuizPosition = null;
				s.viewingLastQuizPosition = false;
				s.chessboard.setFrozen(false);
				if (s.currentQuizPosition && !s.viewingLastQuizPosition) {
					const card = s.currentQuizPosition.card;
					s.markCardReviewed({
						result: s.allReviewPositions[card.id]?.failed ? "incorrect" : "correct",
						id: card.id,
					});
				}
				if (s.currentQuizPosition) {
					s.previousQuizPosition = cloneDeep(s.currentQuizPosition);
				}
				s.currentQuizPosition = s.activeQueue.shift() ?? null;
				s.setupCurrentQuizPosition();
				if (!s.currentQuizPosition) {
					rs.onCardsUpdate();
					UI().cutView();
					todo("push view");
					// UI().pushView(CardReviewComplete);
					trackEvent("review.review_complete");
					return;
				}
			}),

		setupCurrentQuizPosition: () =>
			set(([s, _rs]) => {
				s.quizPositionStage = QuizPositionStage.Start;
				s.incorrectGuessesThisQuizPosition = 0;
				s.chessboard.setTapOptions([]);
				s.chessboard.setMode("normal");
				s.chessboard.highlightSquare(null);
				if (!s.currentQuizPosition) {
					return;
				}
				s.reviewStats.due = s.activeQueue.length;
				s.reviewSide = s.currentQuizPosition.card.side;
				s.chessboard.setPerspective(s.reviewSide);

				s.chessboard.set((c) => {
					c.hideLastMoveHighlight = false;
				});
				if (s.currentQuizPosition.card.previousEpd) {
					let fen = Epd.toFen(s.currentQuizPosition.card.previousEpd);
					const position = createChessProxy(new Chess(fen));
					s.chessboard.setPosition(position);
					s.chessboard.makeMove(s.currentQuizPosition.card.san!, { animate: true, sound: "move" });
				} else {
					let fen = Epd.toFen(s.currentQuizPosition.card.epd);
					const position = createChessProxy(new Chess(fen));
					s.chessboard.setPosition(position);
				}
				s.chessboard.setPerspective(s.currentQuizPosition.card.side);
				s.chessboard.set((c) => {
					c.associatedCard = s.currentQuizPosition!.card;
				});
			}),

		playCorrectMove: (options?: { markAs: "correct" | "incorrect" }) => {
			set(([s]) => {
				let card = s.currentQuizPosition?.card;
				if (!card) {
					return;
				}
				const moveObj = s.chessboard.get((s) => s.position).validateMoves([card.bestMoves[0]])?.[0];
				if (!moveObj) {
					console.error("Invalid move", logProxy(card));
					return;
				}
				s.markRemaining({ markAs: options?.markAs ?? null, completed: true });
				s.chessboard.setFrozen(true);
				s.chessboard.makeMove(moveObj, { animate: true, sound: "move" });
			});
		},
		markRemaining: ({
			markAs,
			// completed,
		}: { markAs: QuizPositionOutcome | null; completed: boolean }) =>
			set(([s]) => {
				if (!s.currentQuizPosition?.card.id) {
					return;
				}
				if (markAs) {
					s.allReviewPositions[s.currentQuizPosition.card.id].failed = markAs === "incorrect";
					s.allReviewPositions[s.currentQuizPosition.card.id].reviewed = true;
				}
			}),
		giveUp: () =>
			set(([s, _rs]) => {
				s.quizPositionStage = QuizPositionStage.Arrows;
				s.markRemaining({ markAs: "incorrect", completed: false });
			}, "giveUp"),
		hasAnnotationsForCurrentQuizPosition: () => {
			return get(([_s, _rs]) => {
				todo("hasAnnotationsForCurrentQuizPosition");
				return false;
			});
		},
		stopReviewing: () =>
			set(([s, rs]) => {
				rs.onCardsUpdate();
				s.chessboard.setTapOptions([]);
				s.onSessionEnd.callAndClear();
			}),
		buildQueue: (options: ReviewOptions) =>
			get(([_s, rs, _gs]) => {
				if (isNil(rs.cards)) {
					return [];
				}
				let queue: CardQuizPosition[] = [];
				const now = new Date();
				// const reviewStyleSetting = () =>
				// 	USER_STATE().getFrontendSetting("reviewStyle") as FrontendSettingOption<ReviewStyle>;
				let cards = rs.cards;
				if (!isDevelopment) {
					cards = shuffle(cards);
				}
				forEach(cards, (card) => {
					// const side = card.side;
					const needsReview =
						SpacedRepetitionStatus.isReviewDue(card.srs!, now) && !card.srs.firstReview;
					const difficult = SpacedRepetitionStatus.isDifficult(card.srs);
					const shouldAdd =
						(options.filter === "difficult-due" && difficult && needsReview) ||
						(options.filter === "new" && card.srs.firstReview) ||
						(typeof options.filter === "function" &&
							options.filter(card) &&
							!card.srs.firstReview) ||
						(options.filter === "recommended" && needsReview) ||
						(options.filter === "due" && needsReview) ||
						(options.filter === "difficult" && difficult) ||
						options.filter === "all" ||
						options.filter === "early";
					if (!shouldAdd) {
						return;
					}
					const quizPosition: CardQuizPosition = {
						card,
					};
					queue.push(quizPosition);
				});
				if (options.filter === "new") {
					queue = reverse(sortBy(queue, (q) => q.card.createdAt));
				}
				return queue;
			}),
		inspectCurrentLine: () =>
			set(([_s, _rs]) => {
				todo("inspectCurrentLine");
			}),
		isCurentMoveVeryDifficult: () =>
			set(([_s]) => {
				return false;
			}),
		invalidateSession: () =>
			set(([s, _rs]) => {
				if (UI().mode === "review") {
					s.onSessionEnd.add(() => {
						set(([s]) => {
							s.activeQueue = [];
						});
					});
				} else {
					s.activeQueue = [];
				}
			}),
		updateQueue: (options: ReviewOptions) =>
			set(([s]) => {
				s.activeQueue = s.buildQueue(options) ?? [];
				s.reviewStats = {
					due: s.activeQueue.length,
					incorrect: 0,
					correct: 0,
				};
			}),
	};

	initialState.chessboard = createChessboardInterface()[1];
	initialState.chessboard.set((c) => {
		// c.frozen = true;
		c.delegate = {
			askForPromotionPiece: (_requestedMove: Move) => {
				return get(([_s]) => {
					return null;
				});
			},
			onPositionUpdated: () => {
				set(([s]) => {
					s.onPositionUpdate();
				});
			},
			madeMove: noop,
			shouldMakeMove: (move: Move) =>
				set(([s]) => {
					let san = move.san;
					let card = s.currentQuizPosition?.card;
					if (!card) {
						return false;
					}
					let moveRating = ChessCard.getMoveRating(card, san);

					let goodEnough = moveRating === MoveRating.Good;
					let moveFeedback: ChessboardMoveFeedback = {
						result:
							moveRating === MoveRating.Good
								? "correct"
								: moveRating === MoveRating.Inaccuracy
									? "inaccuracy"
									: moveRating === MoveRating.Mistake
										? "mistake"
										: moveRating === MoveRating.Blunder
											? "blunder"
											: "blunder",
					};
					let shouldProgress = false;
					if (goodEnough) {
						s.reviewStats.correct++;
						s.allReviewPositions[card.id].failed = false;
						s.allReviewPositions[card.id].reviewed = true;
						shouldProgress = true;
					}
					if (s.incorrectGuessesThisQuizPosition > 1) {
						shouldProgress = false;
						s.quizPositionStage = QuizPositionStage.Full;
					}
					s.chessboard.showMoveFeedback(
						{
							square: move.to as Square,
							result: moveFeedback.result,
							keep: true,
						},
						() => {
							set(([s]) => {
								if (shouldProgress) {
									s.quizPositionStage = QuizPositionStage.Full;
									return;
								}

								let dismiss = () => {
									set(([s]) => {
										s.chessboard.dismissMoveFeedback({
											square: move.to as Square,
											delay: 500,
											callback: () => {
												set(([s]) => {
													s.chessboard.backOne({ clear: true });
													s.chessboard.backOne({ clear: true });
												});
											},
										});
									});
								};
								let position = s.chessboard.get((s) => s.position);
								let refutation = s.currentQuizPosition?.card.refutations[move.san];
								let [refutationMove] = position.validateMoves([refutation!])!;
								devAssert(!!refutationMove, "refutationMove is nil");
								sleep(500).then(() => {
									set(([s]) => {
										s.chessboard
											.makeMove(refutationMove, { animate: true, sound: "move" })
											.then(async () => {
												dismiss();
											});
									});
								});
							});
						},
					);
					return true;
				}),
		};
	});
	return initialState;
};
