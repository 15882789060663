export function yieldToMain() {
	// @ts-ignore
	if ("scheduler" in window && "yield" in window.scheduler) {
		// @ts-ignore
		return window.scheduler.yield();
	}
	return new Promise((resolve) => {
		setTimeout(resolve, 0);
	});
}
