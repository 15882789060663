import { clamp } from "lodash-es";
import type { SpacedRepetitionStatusDTO } from "~/rspc";

export type SpacedRepetitionStatus = SpacedRepetitionStatusDTO & { _dueAtDate?: Date };

export type MasteryLevel = "new" | "learning" | "solid" | "mastered";

export namespace SpacedRepetitionStatus {
	export const isReviewDue = (srs: SpacedRepetitionStatus, _now?: Date): boolean => {
		if (!srs) {
			return false;
		}
		if (!srs._dueAtDate && srs.dueAt) {
			srs._dueAtDate = new Date(srs.dueAt);
		}
		// const nowOld = new Date().toISOString();
		const now = _now ?? new Date();
		// let wouldHaveBeenDue = false;
		let isDue = false;
		// dueAt is set and it's due, or dueAt is not set, meaning never reviewed
		// if ((srs?.dueAt && srs.dueAt < nowOld) || (srs && !srs.dueAt)) {
		// 	wouldHaveBeenDue = true;
		// }
		if ((srs._dueAtDate && dateGte(srs._dueAtDate, now)) || srs.firstReview || !srs.dueAt) {
			isDue = true;
		}
		// if (wouldHaveBeenDue !== isDue) {
		// 	console.log("wouldHaveBeenDue", wouldHaveBeenDue, "isDue", isDue, { srs });
		// }
		return isDue;
		// return false;
	};

	export const isDifficult = (srs: SpacedRepetitionStatus | undefined | null) => {
		return srs?.difficulty ? srs.difficulty > 0.6 : false;
	};

	export const isVeryDifficult = (srs: SpacedRepetitionStatus | undefined | null) => {
		return srs?.difficulty ? srs.difficulty > 0.9 : false;
	};

	export const toMasteryPercentage = (
		srs: SpacedRepetitionStatus | undefined | null,
	): number | null => {
		if (!srs) {
			return null;
		}

		// return 1;
		let x = srs.interval;

		// https://www.wolframalpha.com/input?i=log(x+++1)+*+100/log(101)+,+x+from+0+to+100
		let y = clamp(Math.log(clamp(x, 0, 100) + 1) * (1 / Math.log(101)), 0, 1);
		// console.log(`for interval ${x}, got ${y}`);

		return y;
	};
}

export namespace MasteryLevel {
	export const fromInterval = (interval: number): MasteryLevel => {
		if (interval <= 1) {
			return "new";
		}
		if (interval <= 15) {
			return "learning";
		}
		if (interval < 100) {
			return "solid";
		}
		return "mastered";
	};
}

export function dateGte(x: Date, y: Date) {
	if (y.getFullYear() !== x.getFullYear()) {
		return y.getFullYear() > x.getFullYear();
	}

	if (y.getMonth() !== x.getMonth()) {
		return y.getMonth() > x.getMonth();
	}

	return y.getDate() >= x.getDate();
}
