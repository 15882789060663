import { Chess, type Move } from "@lubert/chess.ts";
import type { Square } from "@lubert/chess.ts/dist/types";
import type { AxiosResponse } from "axios";
import { drop, noop } from "lodash-es";
import { BlunderPuzzle } from "~/types/BlunderPuzzle";
import { Epd } from "~/types/Epd";
import { MoveRating } from "~/types/MoveRating";
import { Side } from "~/types/Side";
import { type AppState, BLUNDER_PUZZLES_STATE, quick } from "./app_state";
import { createChessProxy } from "./chess_proxy";
import { type ChessboardMoveFeedback, createChessboardInterface } from "./chessboard_interface";
import client from "./client";
import { BlunderPuzzlesMode } from "./frontend_settings";
import { rspcClient } from "./rspc_client";
import { StorageItem } from "./storageItem";
import { trackEvent } from "./trackEvent";
import type { UserPuzzleStats } from "./user_state";

export type BlunderPuzzlesState = ReturnType<typeof getInitialBlunderPuzzlesState>;

type Stack = [BlunderPuzzlesState, AppState];

export const getInitialBlunderPuzzlesState = () => {
	const set = <T,>(fn: (stack: Stack) => T, _id?: string) => {
		return quick((s) => {
			return fn([s.trainersState.blunderPuzzlesState, s]);
		});
	};
	const initialState = {
		chessboard: createChessboardInterface()[1],
		onboarded: new StorageItem("blunder-puzzles-onboarded", false),
		puzzleStage: "loading" as "loading" | "opponent_move" | "ready" | "complete",
		puzzleSuccess: null as "correct" | "incorrect" | "gave_up" | null,
		onModeChange: () => {
			set(([s]) => {
				let mode = s.getMode();
				if (mode === BlunderPuzzlesMode.Standard) {
					s.standardState.setupNextPuzzle();
				} else if (mode === BlunderPuzzlesMode.Streak) {
					s.streakState.resetState();
				} else if (mode === BlunderPuzzlesMode.Storm) {
					s.stormState.resetState();
				}
			});
		},
		showResponseRefutation: () => {
			set(([s]) => {
				if (!s.puzzle) {
					return;
				}
				let lastMove = s.chessboard.getLastMove();
				if (!lastMove) {
					return;
				}
				let refutation = s.puzzle.refutations[lastMove.san];
				s.chessboard.makeMove(refutation, { animate: true, sound: "move" });
			});
		},
		showPuzzleSolution: () => {
			set(([s]) => {
				if (!s.puzzle) {
					return;
				}
				let bestMove = s.puzzle.bestMoves[0];
				s.chessboard.makeMove(bestMove, { animate: true, sound: "move" });
			});
		},
		streakState: {
			currentStreak: 0,
			puzzlesQueue: [] as BlunderPuzzle[],
			puzzlesHistory: [] as BlunderPuzzle[],
			streakStatus: "working" as "working" | "broken",
			onPuzzleSuccess: () => {
				set(([s]) => {
					s.streakState.currentStreak += 1;
					s.streakState.setupNextPuzzle();
				});
			},
			onPuzzleFailure: () => {
				set(([s]) => {
					s.streakState.streakStatus = "broken";
					s.puzzleStage = "complete";
					s.showResponseRefutation();
				});
			},
			giveUp: () => {
				set(([s]) => {
					s.puzzleStage = "complete";
					s.puzzleSuccess = "gave_up";
					s.showPuzzleSolution();
				});
			},
			refresh: () => {
				set(([s]) => {
					s.streakState.resetState();
					s.fetchBlunderPuzzles().then((puzzles) => {
						set(([s]) => {
							s.streakState.puzzlesQueue = [...puzzles];
							s.streakState.setupNextPuzzle();
						});
					});
				});
			},
			setupNextPuzzle: () => {
				set(([s]) => {
					trackEvent("blunder_puzzles.streak.new_puzzle");
					if (s.puzzle) {
						s.streakState.puzzlesHistory.push(s.puzzle);
					}
					s.setupPuzzle(s.streakState.puzzlesQueue[0]);
					s.streakState.puzzlesQueue = drop(s.streakState.puzzlesQueue, 1);
				});
			},
			resetState: () => {
				set(([s]) => {
					s.clearPuzzle();
					s.streakState.streakStatus = "working";
					s.streakState.currentStreak = 0;
					s.streakState.puzzlesQueue = [];
					s.streakState.puzzlesHistory = [];
				});
			},
			newStreak: () => {
				set(([s]) => {
					s.streakState.resetState();
				});
			},
			completed: [] as BlunderPuzzle[],
		},
		puzzle: null as BlunderPuzzle | null,
		clearPuzzle: () => {
			set(([s]) => {
				s.puzzle = null;
				s.chessboard.resetPosition();
				s.chessboard.setPerspective("white");
			});
		},
		standardState: {
			currentPuzzle: null as BlunderPuzzle | null,
			puzzles: [] as BlunderPuzzle[],
			giveUp: () => {
				set(([s]) => {
					s.puzzleStage = "complete";
					s.puzzleSuccess = "gave_up";
					s.showPuzzleSolution();
				});
			},
			refreshPuzzles: () => {
				set(([s]) => {
					s.fetchBlunderPuzzles().then((puzzles) => {
						set(([s]) => {
							s.standardState.puzzles = [...puzzles];
							s.standardState.setupNextPuzzle();
						});
					});
				});
			},
			setupNextPuzzle: () => {
				set(([s]) => {
					s.setupPuzzle(s.standardState.puzzles[0]);
					s.standardState.currentPuzzle = s.standardState.puzzles[0];
					s.standardState.puzzles = drop(s.standardState.puzzles, 1);
					if (s.standardState.puzzles.length < 3) {
						s.fetchBlunderPuzzles().then((puzzles) => {
							set(([s]) => {
								s.standardState.puzzles = [...s.standardState.puzzles, ...puzzles];
							});
						});
					}
				});
			},
			onPuzzleSuccess: () => {
				set(([_s]) => {});
			},
			onPuzzleFailure: () => {
				set(([s]) => {
					s.chessboard.backOne({ clear: true });
				});
			},
		},
		stormState: {
			currentPuzzle: null as BlunderPuzzle | null,
			puzzles: [] as BlunderPuzzle[],
			resetState: () => {
				set(([s]) => {
					s.stormState.currentPuzzle = null;
					s.stormState.puzzles = [];
				});
			},
		},
		blunderPuzzles: [] as BlunderPuzzle[],
		fetchBlunderPuzzles: async (): Promise<BlunderPuzzle[]> => {
			let mode = BLUNDER_PUZZLES_STATE().getMode();
			// let blunderPuzzleEval = USER_STATE().getFrontendSetting("blunderPuzzlesEval");
			// let _blunderPuzzleMode = USER_STATE().getFrontendSetting("blunderPuzzlesMode");
			// let blunderPuzzleGamePhase = USER_STATE().getFrontendSetting("blunderPuzzlesGamePhase");
			let params = {} as any;
			// if (blunderPuzzleEval.value !== BlunderPuzzlesEval.Mixed) {
			// 	params.positionEvaluation =
			// 		blunderPuzzleEval.value === BlunderPuzzlesEval.Even ? "even" : "winning";
			// }
			// if (mode === "standard") {
			// 	params.count = 5;
			// }
			if (mode === "streak") {
				params.streak = true;
			}
			// if (blunderPuzzleGamePhase.value !== BlunderPuzzleGamePhase.Any) {
			// 	switch (blunderPuzzleGamePhase.value) {
			// 		case BlunderPuzzleGamePhase.Opening:
			// 			params.phase = "opening";
			// 			break;
			// 		case BlunderPuzzleGamePhase.Middlegame:
			// 			params.phase = "middlegame";
			// 			break;
			// 		case BlunderPuzzleGamePhase.Endgame:
			// 			params.phase = "endgame";
			// 			break;
			// 	}
			// }
			const puzzles = await rspcClient.query(["puzzles.blunderPuzzles.list", params]);
			return puzzles.blunderPuzzles;
		},
		initState: () => {
			set(([s]) => {
				if (BLUNDER_PUZZLES_STATE().getMode() === "standard") {
					s.standardState.refreshPuzzles();
				} else if (BLUNDER_PUZZLES_STATE().getMode() === "storm") {
					s.stormState.resetState();
				} else if (BLUNDER_PUZZLES_STATE().getMode() === "streak") {
					s.streakState.refresh();
				}
			});
		},
		setupPuzzle: (puzzle: BlunderPuzzle) => {
			set(([s]) => {
				let fen = Epd.toFen(puzzle.previousEpd);
				const puzzlePosition = createChessProxy(new Chess(fen));
				s.puzzle = puzzle;
				s.chessboard.setPerspective(Side.flip(Side.fromColor(puzzlePosition.turn())));
				s.chessboard.setPosition(puzzlePosition);
				s.chessboard.getLastMove;
				s.puzzleStage = "opponent_move";
				s.puzzleSuccess = null;
				let move = puzzlePosition.validateMoves([puzzle.san])![0];
				s.chessboard.setFrozen(true);
				setTimeout(() => {
					set(([s]) => {
						s.puzzleStage = "ready";
						s.chessboard.setFrozen(false);
						s.chessboard.makeMove(move, { sound: "move", animate: true });
					});
				}, 1000);
				s.chessboard.set((cb) => {
					cb.associatedLink = puzzle.link;
				});
			});
		},
		getCurrentPuzzle: () => {
			return BLUNDER_PUZZLES_STATE().puzzle;
		},
		recordPuzzleCompletion: (puzzle: BlunderPuzzle, success: boolean) => {
			client
				.post("/api/v1/blunder_puzzles/puzzle_completed", {
					puzzleId: puzzle.id,
					passed: success,
				})
				.then(({ data }: AxiosResponse<UserPuzzleStats>) => {
					quick((s) => {
						s.userState.puzzleStats = data;
					});
				});
		},
		getMode: (): BlunderPuzzlesMode => {
			return BlunderPuzzlesMode.Streak;
			// return (
			// 	USER_STATE().getFrontendSetting(
			// 		"blunderPuzzlesMode",
			// 	) as FrontendSettingOption<BlunderPuzzlesMode>
			// ).value;
		},
	};
	initialState.chessboard.set((c) => {
		c.delegate = {
			completedMoveAnimation: noop,
			onPositionUpdated: () => {},

			madeManualMove: () => {
				set(([_s, _rs]) => {});
			},
			onBack: () => {
				set(([_s]) => {
					// todo
				});
			},
			onReset: () => {
				set(([_s]) => {
					// todo
				});
			},
			onPositionUpdate: () => {
				set(([s, _rs]) => {
					s.chessboard.highlightSquare(null);
				});
			},
			shouldMakeMove: (move: Move) =>
				set(([s]) => {
					let san = move.san;
					let puzzle = s.getCurrentPuzzle();
					if (s.puzzleStage === "complete") {
						return false;
					}
					if (!puzzle) {
						return false;
					}
					let moveRating = BlunderPuzzle.getMoveRating(puzzle, san);
					let goodEnough = moveRating === MoveRating.Good || moveRating === MoveRating.Inaccuracy;
					let moveFeedback: ChessboardMoveFeedback = {
						result:
							moveRating === MoveRating.Good
								? "correct"
								: moveRating === MoveRating.Inaccuracy
									? "acceptable-inaccuracy"
									: moveRating === MoveRating.Mistake
										? "mistake"
										: moveRating === MoveRating.Blunder
											? "blunder"
											: "blunder",
					};
					if (!s.puzzleSuccess) {
						if (
							BLUNDER_PUZZLES_STATE().getMode() === "standard" ||
							BLUNDER_PUZZLES_STATE().getMode() === "streak"
						) {
							s.recordPuzzleCompletion(puzzle, goodEnough);
						}
						if (goodEnough) {
							s.puzzleSuccess = "correct";
						} else {
							s.puzzleSuccess = "incorrect";
						}
					}
					s.chessboard.showMoveFeedback(
						{ square: move.to as Square, result: moveFeedback.result },
						() => {
							set(([s]) => {
								if (goodEnough) {
									s.puzzleStage = "complete";
									s.puzzleSuccess = "correct";
									if (s.getMode() === "streak") {
										s.streakState.onPuzzleSuccess();
									} else if (s.getMode() === "standard") {
										s.standardState.onPuzzleSuccess();
									}
								} else {
									if (s.getMode() === "streak") {
										s.streakState.onPuzzleFailure();
									} else if (s.getMode() === "standard") {
										s.standardState.onPuzzleFailure();
									}
								}
							});
						},
					);
					if (moveRating === MoveRating.Good || moveRating === MoveRating.Inaccuracy) {
						return true;
					}
					return true;
				}),
		};
	});
	return initialState;
};
