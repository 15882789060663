export namespace EloRange {
	export function fromString(s: string): [number, number] {
		if (!s.includes("-")) {
			return [2500, 2800];
		}
		const [min, max] = s.split("-").map((v) => Number.parseInt(v, 10));
		return [min, max];
	}

	export function asString(range: [number, number]): string {
		if (range[0] === 2500) {
			return "2500+";
		}
		return `${range[0]}-${range[1]}`;
	}

	export function fromSystemAndRange(
		ratingSystem: string,
		range: [number, number],
	): [number, number] {
		const liLowest: [number, number] = [0, 1000];
		const li1400: [number, number] = [1400, 1600];
		const _li1600: [number, number] = [1600, 1800];
		const li1800: [number, number] = [1800, 2000];
		const li2000: [number, number] = [2000, 2200];
		const li2200: [number, number] = [2200, 2500];
		const liHighest: [number, number] = [2500, 2800];

		const minElo = range[0];

		if (ratingSystem === RatingSource.Lichess) {
			return range;
		}

		switch (ratingSystem) {
			case RatingSource.ChessCom:
				switch (minElo) {
					case 0:
						return liLowest;
					case 1000:
					case 1200:
					case 1400:
						return li1400;
					case 1600:
						return li1800;
					case 1800:
						return li2000;
					case 2000:
						return li2000;
					case 2200:
						return li2200;
					case 2500:
						return liHighest;
					default:
						return liHighest;
				}

			case RatingSource.USCF:
			case RatingSource.FIDE:
				switch (minElo) {
					case 0:
						return liLowest;
					case 1000:
					case 1200:
					case 1400:
						return li1400;
					case 1600:
						return li1800;
					case 1800:
						return li2000;
					case 2000:
						return li2200;
					case 2200:
						return liHighest;
					default:
						return liHighest;
				}

			default:
				throw new Error(`Unknown rating system ${ratingSystem}`);
		}
	}
}

export enum RatingSource {
	Lichess = "Lichess",
	ChessCom = "Chess.com",
	USCF = "USCF",
	FIDE = "FIDE",
}
