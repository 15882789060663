import { Chess, type Move } from "@lubert/chess.ts";
import type { Square } from "@lubert/chess.ts/dist/types";
import { filter, find, first, isNil, map, some } from "lodash-es";
import {
	type Accessor,
	For,
	type JSX,
	Show,
	createEffect,
	createMemo,
	createSignal,
} from "solid-js";
import { Portal } from "solid-js/web";
import { TransitionGroup } from "solid-transition-group";
import { useHovering } from "~/mocks";
import type { Side } from "~/types/Side";
import type { TableResponse } from "~/types/TableResponse";
import type { EnrichedComponent } from "~/types/View";
import { APP_STATE, REPERTOIRE_STATE, REVIEW_STATE, UI, quick, useMode } from "~/utils/app_state";
import { START_EPD } from "~/utils/chess";
import { clsx } from "~/utils/classes";
import { isDevelopment } from "~/utils/env";
import { isMobile } from "~/utils/isMobile";
import { pieceSymbolToPieceName } from "~/utils/plans";
import { Quiz } from "~/utils/queues";
import { registerViewMode } from "~/utils/register_view_mode";
import { QuizGroupStage } from "~/utils/review_state";
import { onBack } from "~/utils/signals/onBack";
import { onKeyPress } from "~/utils/signals/onKeyPress";
import { c } from "~/utils/styles";
import {
	BOARD_THEMES_BY_ID,
	type BoardTheme,
	COMBINED_THEMES_BY_ID,
	type CombinedTheme,
	combinedThemes,
} from "~/utils/theming";
import { trackEvent } from "~/utils/trackEvent";
import { ChessboardArrowView } from "./ChessboardArrow";
import { SidebarHeader } from "./RepertoireEditingHeader";
import { RepertoireMovesTable } from "./RepertoireMovesTable";
import { type SidebarAction, SidebarActions } from "./SidebarActions";
import { animateSidebar } from "./SidebarContainer";
import { type AnimateSidebar, SidebarSlideContainer } from "./SidebarSlideContainer";
import { SidebarTemplate } from "./SidebarTemplate";

export const RepertoireReview: EnrichedComponent = () => {
	createEffect(() => {
		if (REVIEW_STATE().previousQuizGroup) {
			onBack(() => {
				quick((s) => {
					animateBottomSidebarSection()?.("left");
					s.repertoireState.reviewState.backToLastQuizGroup();
				});
			}, "stay");
		}
	});
	const completedReviewPositionMoves = () => REVIEW_STATE().completedReviewPositionMoves;
	const currentMove = () => REVIEW_STATE().currentQuizGroup;
	const stage = () => REVIEW_STATE().quizGroupStage;
	onKeyPress((key) => {
		if (!isDevelopment) {
			return;
		}
		if (key === "d") {
			quick((s) => {
				const correctNextMove = s.repertoireState.reviewState.getNextReviewPositionMove();
				if (!correctNextMove) {
					return;
				}
				const legalMoves = s.repertoireState.reviewState.chessboard
					.get((c) => c.position)
					.moves({ verbose: true });
				const badMoves = filter(legalMoves, (m) => m.san !== correctNextMove.sanPlus);
				// pick randomly
				const legalMove = badMoves[Math.floor(Math.random() * badMoves.length)];
				s.repertoireState.reviewState.chessboard.requestToMakeMove(legalMove!);
			});
		}
		if (key === "a") {
			quick((s) => {
				const correctNextMove = s.repertoireState.reviewState.getNextReviewPositionMove();
				if (!correctNextMove) {
					return;
				}
				const move = s.repertoireState.reviewState.chessboard
					.get((c) => c.position)
					.validateMoves([correctNextMove.sanPlus])?.[0]!;
				if (!move) {
					return;
				}
				s.repertoireState.reviewState.chessboard.requestToMakeMove(move);
			});
		}
	});
	const viewingLastQuizGroup = () => REVIEW_STATE().viewingLastQuizGroup;
	const failedDifficultMove = () => REVIEW_STATE().failedDifficultMove;
	const reviewOptions = () => APP_STATE().repertoireState.reviewState.activeOptions;
	const reviewingMistakes = () => reviewOptions()?.lichessMistakes;
	const mode = useMode();
	const side = () => APP_STATE().repertoireState.reviewState.reviewSide as Side;
	const onboarding = () => REPERTOIRE_STATE().onboarding;
	const reviewStats = () => REPERTOIRE_STATE().reviewState.reviewStats;
	const progressIcons = () => {
		if (reviewingMistakes()) {
			return [
				{
					// icon: "fa fa-circle-x",
					icon: null,
					class: "text-orange-60",
					text: `${reviewStats().due + 1} Remaining`,
				},
			];
		}
		return [
			{
				icon: "fa fa-clock",
				class: "text-yellow-60",
				text: `${reviewStats().due + 1} Due`,
			},
			{
				icon: "fa-kit fa-tick",
				class: "text-inverse bg-green-60 rounded-full",
				text: reviewStats().correct,
			},
			{
				icon: "fa-kit fa-cross",
				class: "text-inverse bg-red-60 rounded-full",
				text: reviewStats().incorrect,
			},
		];
	};
	const actions: Accessor<(SidebarAction & { hidden?: boolean })[]> = () => {
		const actions: SidebarAction[] = [];
		const inspectLineAction: SidebarAction = {
			onPress: () => {
				quick((s) => {
					trackEvent(`${mode()}.inspect_line`);
					s.repertoireState.reviewState.inspectCurrentLine();
				});
			},
			style: "primary",
			text: "Exit practice and view in repertoire builder",
		};
		const moves = currentMove() ? Quiz.getMoves(currentMove()!) : [];
		const learningAny = some(moves, (m) => m.learning);
		if (stage() === QuizGroupStage.Full) {
			actions.push({
				onPress: () => {
					quick((s) => {
						animateBottomSidebarSection()?.("right");
						s.repertoireState.reviewState.setupNextQuizGroup();
					});
				},
				style: "focus",
				text: "Got it, continue practicing",
			});
			if (!onboarding().isOnboarding) {
				actions.push(inspectLineAction);
			}
		} else if (failedDifficultMove()) {
			actions.push({
				onPress: () => {
					quick((s) => {
						animateSidebar("right");
						trackEvent(`${mode()}.inspect_difficult_move`);
						s.repertoireState.reviewState.inspectCurrentLine();
					});
				},
				style: "focus",
				text: "Exit practice and view in repertoire builder",
			});
			actions.push({
				onPress: () => {
					quick((s) => {
						animateBottomSidebarSection()?.("right");
						s.repertoireState.reviewState.setupNextQuizGroup();
					});
				},
				style: "primary",
				text: "Continue practicing",
			});
		} else if (!learningAny && stage() !== QuizGroupStage.Arrows) {
			const whichPrompt: "skip" | "hint" | "arrows" = isPlanPractice()
				? "skip"
				: hasAnnotations() && stage() < QuizGroupStage.Hints
					? "hint"
					: "arrows";
			actions.push({
				onPress: () => {
					quick((s) => {
						if (whichPrompt === "skip" || whichPrompt === "hint") {
							animateBottomSidebarSection()?.("right");
						}
						trackEvent(`${mode()}.give_up`);
						s.repertoireState.reviewState.giveUp();
					});
				},
				style: "primary",
				text:
					whichPrompt === "skip"
						? "I don't know, skip this one"
						: whichPrompt === "hint"
							? "I don't know, give me a hint"
							: "I don't know, show me the answer",
			});
			if (currentMove()?.lichessMistake) {
				actions.push({
					onPress: () => {
						quick((s) => {
							animateBottomSidebarSection()?.("right");
							s.repertoireState.reviewState.setupNextQuizGroup();
						});
					},
					style: "primary",
					text: "Skip this move, I meant to play it",
				});
			}
			if (!onboarding().isOnboarding && currentMove()) {
				actions.push(inspectLineAction);
			}
		}

		if (learningAny) {
			actions.push({
				style: "secondary",
				text: "Skip first practice",
				onPress: () => {
					UI().pushView(SidebarTemplate, {
						props: {
							header: "Skip first practice?",
							bodyPadding: true,
							children: (
								<p class="body-text">
									This will skip the first practice mode for all moves in your repertoire.
								</p>
							),
							actions: [
								{
									onPress: () => {
										quick((s) => {
											s.repertoireState.reviewState.skipFirstPractice();
											s.repertoireState.skipFirstPractice();
											UI().backOne();
										});
									},
									style: "primary",
									text: "Yes, skip first practice",
								},
								{
									onPress: () => {
										quick((_s) => {
											UI().backOne();
										});
									},
									style: "primary",
									text: "Cancel",
								},
							],
						},
					});
				},
			});
			actions.push({ ...inspectLineAction, style: "secondary" });
		}
		return actions;
	};
	const userState = APP_STATE().userState;
	const user = () => userState.user;
	const combinedTheme: Accessor<CombinedTheme> = createMemo(
		() =>
			find(combinedThemes, (theme) => theme.boardTheme === user()?.theme) ||
			COMBINED_THEMES_BY_ID.default,
	);
	const theme: Accessor<BoardTheme> = () => BOARD_THEMES_BY_ID[combinedTheme().boardTheme];
	const num = () => Quiz.getMoves(currentMove()!)?.length ?? 0;
	const isPlanPractice = () => {
		if (!currentMove()) {
			return false;
		}
		return !!Quiz.getPlans(currentMove()!);
	};
	const reviewState = () => APP_STATE().repertoireState.reviewState;
	const hasAnnotations = () => {
		return REVIEW_STATE().hasAnnotationsForCurrentQuizGroup();
	};

	const movesTable: Accessor<JSX.Element> = createMemo(() => {
		if (!currentMove()) {
			return null;
		}
		const moves = Quiz.getMoves(currentMove()!);
		if (isNil(moves)) {
			return null;
		}
		const tableResponses: TableResponse[] = moves.map((move) => {
			const hasPlayedThisMove = completedReviewPositionMoves()?.[move.sanPlus];
			const showSan = hasPlayedThisMove || stage() >= QuizGroupStage.Arrows || move.learning;
			return {
				side: side(),
				tags: [],
				score: 0,
				repertoireMove: move,
				reviewInfo: {
					hideSan: !showSan,
					hideAnnotations: !move.learning && !hasPlayedThisMove && stage() === QuizGroupStage.Start,
					learning: move.learning,
				},
			} as TableResponse;
		});
		createEffect(() => {});
		return (
			<RepertoireMovesTable
				allLocalStockfish={false}
				canAnnotate={stage() >= QuizGroupStage.Arrows}
				unclickable
				epd={currentMove()?.epd}
				usePeerRates={() => false}
				hideHeaders
				activeSide={side()}
				side={side()}
				responses={() => tableResponses}
			/>
		);
	});
	const body: Accessor<JSX.Element> = () => {
		const lichessMistake = currentMove()?.lichessMistake;
		if (failedDifficultMove()) {
			const move = Quiz.getMoves(currentMove()!)?.[0];
			if (!move) {
				return null;
			}
			return (
				<>
					<p>
						The move in your repertoire is
						<span
							class={clsx(
								"text-teal-40 font-semibold cursor-pointer hover:bg-orange-10 p-0.5 -mr-0.5 rounded-sm",
							)}
						>
							{move.sanPlus}
						</span>
						.
					</p>
					<p class="mt-2">
						You seem to be having trouble with this move. Maybe it’s time to review your repertoire
						and choose something you find more intuitive?
					</p>
				</>
			);
		}
		if (reviewingMistakes() && lichessMistake) {
			return (
				<>
					In this position in{" "}
					<a
						href={
							lichessMistake.source === "lichess"
								? `https://lichess.org/${lichessMistake.gameId}`
								: `https://chess.com/game/live/${lichessMistake.gameId}`
						}
						target={"_blank"}
						rel="noreferrer"
						class="font-semibold "
					>
						your game
						{lichessMistake.opponentName && ` against ${lichessMistake.opponentName}`}
					</a>
					, you played{" "}
					<span
						class={clsx(
							"text-orange-50 font-semibold cursor-pointer hover:bg-orange-10 p-0.5 -mr-0.5 rounded-sm",
						)}
						{...wrongMoveHoverProps}
					>
						{lichessMistake.playedSan}
					</span>
					. Play the correct move on the board.
				</>
			);
		}
		if (stage() === QuizGroupStage.Full && !isPlanPractice()) {
			if (num() === 1) {
				return "This move is in your repertoire";
			}
			if (num() > 1 && viewingLastQuizGroup()) {
				return "You have multiple moves in your repertoire for this position";
			}
		}
		const plans = currentMove()
			? Quiz.getRemainingPlans(currentMove()!, reviewState().planIndex)
			: [];
		if (isPlanPractice()) {
			const plan = first(plans);
			if (!plan) {
				return <>These are your plans from this position, take a second to review them</>;
			}
			if (plan.type === "castling") {
				return (
					<>
						Which side does{" "}
						<span
							class="rounded-sm p-1 py-0.5 font-bold"
							style={{ "background-color": theme().highlightNextMove }}
						>
							{side()}
						</span>{" "}
						usually castle to? Tap on the board to indicate the correct square.
					</>
				);
			}
			return (
				<>
					Where does the{" "}
					<span
						class="rounded-sm p-1 py-0.5 font-bold"
						style={{ "background-color": theme().highlightNextMove }}
					>
						{pieceSymbolToPieceName(plan.piece)} on {plan.fromSquare}
					</span>{" "}
					usually belong? Tap on the board to indicate the correct square.
				</>
			);
		}
		const moves = Quiz.getMoves(currentMove()!);
		if (onboarding().isOnboarding || !isMobile()) {
			if (moves?.length === 1) {
				if (moves[0].epd === START_EPD) {
					return "Play your first move on the board";
				}
				return "Play the correct move on the board";
			}
			return `You have ${moves?.length} responses to this position in your repertoire. Play all your responses on the board`;
		}
	};
	const { hovering: wrongMoveHovered, hoveringProps: wrongMoveHoverProps } = useHovering();
	const arrowMoves = () => {
		const animatingPosition = REVIEW_STATE().chessboard.getIsAnimating();
		if (animatingPosition) {
			return [];
		}
		const learningAny = some(Quiz.getMoves(currentMove()!) ?? [], (m) => m.learning);
		const moves = Quiz.getMoves(currentMove()!)?.filter((_m) => {
			if (stage() === QuizGroupStage.Arrows) {
				return true;
			}
			if (learningAny) {
				return true;
			}
			return false;
			// return m.learning;
		});
		if (!moves || moves.length === 0) {
			return [];
		}
		const fen = `${moves[0].epd} 0 1`;
		const position = new Chess(fen);
		const moveObjects = moves.map((m) => {
			return position.validateMoves([m.sanPlus])![0];
		});
		return map(moveObjects, (m: Move) => {
			return {
				move: m,
				played: !!completedReviewPositionMoves()[m.san],
			};
		});
	};
	const mistakeMove = () => {
		const lichessMistake = currentMove()?.lichessMistake;
		if (!lichessMistake) {
			return null;
		}
		const fen = `${lichessMistake.epd} 0 1`;
		const position = new Chess(fen);
		const [move] = position.validateMoves([lichessMistake.playedSan]) as [Move];
		return move;
	};

	const mountPoint = APP_STATE().repertoireState.reviewState.chessboard.get(
		(s) => s.refs.arrowsContainerRef,
	);
	const arrowsUnderneathMountPoint = APP_STATE().repertoireState.reviewState.chessboard.get(
		(s) => s.refs.arrowsUnderneathContainerRef,
	);
	const flipped = () => APP_STATE().repertoireState.reviewState.chessboard.get((s) => s.flipped);
	const [animateBottomSidebarSection, setAnimateBottomSidebarSection] =
		createSignal<AnimateSidebar | null>(null);
	const dragging = () => REVIEW_STATE().chessboard.get((s) => !!s.drag.square);
	return (
		<>
			<Portal mount={arrowsUnderneathMountPoint ?? undefined}>
				<TransitionGroup
					onEnter={(el, done) => {
						const a = el.animate([{ opacity: 0 }, { opacity: 1 }], {
							duration: 200,
						});
						a.finished.then(done);
					}}
					onExit={(el, done) => {
						const a = el.animate([{ opacity: 1 }, { opacity: 0 }], {
							duration: 200,
						});
						a.finished.then(done);
					}}
				>
					<Show when={!dragging() && UI().currentView()?.component === RepertoireReview}>
						<For each={arrowMoves()}>
							{(learningMove) => (
								<div>
									<ChessboardArrowView
										focused={false}
										faded={!!dragging() || learningMove.played}
										color={c.orange[55]}
										flipped={flipped()}
										toSquare={learningMove.move.to as Square}
										fromSquare={learningMove.move.from as Square}
									/>
								</div>
							)}
						</For>
					</Show>
				</TransitionGroup>
			</Portal>
			<Portal mount={mountPoint ?? undefined}>
				<Show when={currentMove()?.lichessMistake}>
					<ChessboardArrowView
						faded={false}
						color={c.orange[55]}
						focused={wrongMoveHovered()}
						flipped={flipped()}
						hidden={!wrongMoveHovered()}
						toSquare={mistakeMove()?.to as Square}
						fromSquare={mistakeMove()?.from as Square}
					/>
				</Show>
			</Portal>
			<Show when={currentMove()?.synthetic && isDevelopment && false}>
				<p class="text-secondary text-xs font-semibold">
					This move is synthetic and will not be reviewed
				</p>
			</Show>
			<SidebarTemplate header={null} actions={[]}>
				<div class={"row w-full items-baseline justify-between padding-sidebar"}>
					<SidebarHeader>
						{reviewingMistakes()
							? "Reviewing mistakes"
							: isMobile()
								? "Practice"
								: `Practicing ${isPlanPractice() ? "plans" : "moves"}`}
					</SidebarHeader>
					<div class="row items-center space-x-4 lg:space-x-8">
						<For each={progressIcons()}>
							{(i) => {
								return (
									<div class="row items-center">
										<p
											class={clsx("text-sm font-semibold lg:text-base text-secondary tabular-nums")}
										>
											{i.text}
										</p>
										<Show when={i.icon}>
											<i class={clsx(i.class, i.icon, " ml-2 text-sm lg:text-base")} />
										</Show>
									</div>
								);
							}}
						</For>
					</div>
				</div>
				<div class={"h-4 lg:h-10"} />
				<SidebarSlideContainer
					setAnimateSidebar={(fn) => setAnimateBottomSidebarSection(() => fn)}
					class={"space-y-4 lg:space-y-6"}
				>
					<Show when={body()}>
						<p class="body-text leading-5 padding-sidebar">{body()}</p>
					</Show>
					{movesTable()}
					<SidebarActions actions={actions()} />
				</SidebarSlideContainer>
			</SidebarTemplate>
		</>
	);
};

registerViewMode(RepertoireReview, "review");
