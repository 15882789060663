import { Chess } from "@lubert/chess.ts";
import { Stockfish as NativeStockfish } from "capacitor-stockfish";
import { Epd } from "~/types/Epd";
import { Side } from "~/types/Side";
import { StockfishEval } from "~/types/StockfishEval";
import { moveToLan } from "./chess";
import { getFeatures } from "./device";
import { isNative } from "./env";
import { MultiCallback } from "./multi_callback";

let ENGINE: any = null;
export const EVAL_NODES = {
	SHALLOW: isNative ? 100_000 : 200_000,
	STANDARD: isNative ? 250_000 : 500_000,
	// MEDIUM: 200_000,
	DEEP: isNative ? 3_000_000 : 8_000_000,
} as { SHALLOW: number; STANDARD: number; DEEP: number };
let hasCalledLoad = false;

const postMessage = (msg: string) => {
	if (isNative) {
		NativeStockfish.cmd({ cmd: msg });
	} else {
		ENGINE?.postMessage(msg);
	}
};

let STOCKFISH_LOADED = false;

export const loadStockfish = () => {
	if (hasCalledLoad) {
		return ENGINE!;
	}
	if (isNative) {
		hasCalledLoad = true;
		window.addEventListener("stockfish", (event: any) => {
			if ("output" in event) {
				handleUciMessage(event.output);
			}
		});
		console.info("[STOCKFISH] Loading native stockfish", NativeStockfish);
		NativeStockfish.start().then(() => {
			console.info("[STOCKFISH] Native stockfish started");
			STOCKFISH_LOADED = true;
			Promise.all([NativeStockfish.getMaxMemory(), NativeStockfish.getProcessorCount()]).then(
				([maxMemory, processorCount]) => {
					console.info("[STOCKFISH] Got max memory", maxMemory);
					console.info("[STOCKFISH] Got processor count", processorCount);
					StockfishInterface.setMemory(maxMemory.value * 0.5);
					StockfishInterface.setThreads(Math.max(processorCount.value - 1, 1));
					// sortaTest();
				},
			);
		});
		return;
	}
	if (
		getFeatures().includes("sharedMem") &&
		getFeatures().includes("wasm") &&
		getFeatures().includes("simd")
	) {
		hasCalledLoad = true;
		let script = document.createElement("script");
		script.src = "/js/stockfish.js";
		document.body.appendChild(script);
		script.onload = () => {
			// @ts-ignore
			Stockfish().then((sf: any) => {
				ENGINE = sf;
				sf.addMessageListener((line: string) => {
					handleUciMessage(line);
				});

				sf.postMessage("uci");
				// set threads
				let threads = navigator.hardwareConcurrency;
				StockfishInterface.setMemory(10);
				StockfishInterface.setThreads(Math.max(threads - 1, 1));
				STOCKFISH_LOADED = true;
				// sortaTest();
			});
		};
		// return new Promise<StockfishWeb>((resolve, reject) => {
		// 	const stockfishUrl = new URL("/js/sf17-79.js", window.location.origin);
		// 	import(/* @vite-ignore */ stockfishUrl.href).then((makeModule: any) => {
		// 		let memory = sharedWasmMemory(2560!);
		// 		const memoryInBytes = memory.buffer.byteLength;
		// 		const memoryMb = Math.floor(memoryInBytes / 1024 / 1024) / 2;
		// 		let threads = navigator.hardwareConcurrency;
		// 		threads = 4;
		// 		console.debug(`Threads: ${threads}/${navigator.hardwareConcurrency}`);
		// 		console.debug(`Memory size: ${memoryMb} MB`);
		// 		console.debug("Specs", getFeatures());
		//
		// 		makeModule
		// 			.default({
		// 				wasmMemory: memory,
		// 				debug: true,
		// 				// useWebWorker: true,
		// 				// numWorkers: 4,
		// 				onError: (msg: string) => {
		// 					console.error("Error loading stockfish", msg);
		// 					reject(new Error(msg));
		// 				},
		// 				locateFile: (name: string) => `/js/${name}`,
		// 			})
		// 			.then(async (instance: StockfishWeb) => {
		// 				instance.onError = (msg: string) => {
		// 					console.error("Error loading stockfish", msg);
		// 					reject(new Error(msg));
		// 				};
		// 				instance;
		// 				console.debug(`/js/${instance.getRecommendedNnue(0)}`);
		// 				console.debug(`/js/${instance.getRecommendedNnue(1)}`);
		// 				Promise.all([
		// 					fetch(`/js/${instance.getRecommendedNnue(0)}`),
		// 					fetch(`/js/${instance.getRecommendedNnue(1)}`),
		// 				]).then((responses) => {
		// 					Promise.all([responses[0].arrayBuffer(), responses[1].arrayBuffer()]).then(
		// 						(buffers) => {
		// 							console.log("setting buffers", buffers);
		// 							instance.setNnueBuffer(new Uint8Array(buffers[0]), 0);
		// 							instance.setNnueBuffer(new Uint8Array(buffers[1]), 1);
		//
		// 							StockfishInterface.uciCmd("uci");
		// 							StockfishInterface.onReady().then(() => {
		// 								StockfishInterface.setMemory(10);
		// 								StockfishInterface.setThreads(threads);
		// 							});
		// 							sortaTest();
		// 						},
		// 					);
		// 				});
		// 				resolve(instance);
		// 				instance.listen = (a) => {
		// 					handleUciMessage(a);
		// 				};
		// 				ENGINE = instance;
		// 			})
		// 			.catch((e: any) => {
		// 				console.error("Error loading stockfish", e);
		// 				reject(e);
		// 			});
		// 	});
		// });
		return;
	}

	console.warn("Can't load stockfish!");
};

const EVAL_CACHE: Record<string, StockfishEval> = {};

const toEvalCacheKey = (epd: string, nodes: number) => `${epd}|${nodes}`;

export type StockfishMove = {
	san: string;
	stockfishEval: StockfishEval;
	final?: boolean;
};

export const StockfishInterface = {
	position: new Chess() as Chess | null,
	loaded: false,
	nodeGoal: EVAL_NODES.STANDARD as number,
	isReady: false,
	running: false,
	readyCallback: new MultiCallback<() => void>(),
	loadedCallback: new MultiCallback<() => void>(),
	evalUpdatedCallback: new MultiCallback<(_: StockfishEval) => void>(),
	topMovesUpdatedCallback: new MultiCallback<(_: StockfishMove[]) => void>(),
	topMovesFinalCallback: new MultiCallback<(_: StockfishMove[]) => void>(),
	finalEvalCallback: new MultiCallback<(_: StockfishEval) => void>(),
	okCallback: new MultiCallback<() => void>(),
	// epd to map of uci lan moves to san moves
	legalMoves: {} as Record<string, string>,
	uciCmd: (cmd: string) => {
		// console.debug("[Stockfish postMessage]", cmd);
		postMessage(cmd);
	},
	getOption: (name: string) => {
		return StockfishInterface.uciCmd(`option name ${name} value`);
	},
	setOption: (name: string, value: string) => {
		StockfishInterface.uciCmd(`setoption name ${name} value ${value}`);
	},
	setMemory: (mb: number) => {
		StockfishInterface.setOption("Hash", `${mb}`);
	},
	setThreads: (threads: number) => {
		StockfishInterface.setOption("Threads", `${threads}`);
	},
	multiPvSetting: 1,
	setMultiPv: (multipv: number) => {
		StockfishInterface.setOption("MultiPV", `${multipv}`);
		StockfishInterface.multiPvSetting = multipv;
	},
	getTopMoves: (
		epd: string,
		{ pv, nodes }: { pv: number; nodes: number },
		progressCallback?: (moves: StockfishMove[]) => void,
	): Promise<StockfishMove[]> => {
		StockfishInterface.cancel();
		StockfishInterface.setMultiPv(pv);
		StockfishInterface.setPosition(epd);
		if (progressCallback) {
			StockfishInterface.topMovesUpdatedCallback.add(progressCallback);
		}
		StockfishInterface.go(epd, { nodes });
		const promise = new Promise<StockfishMove[]>((resolve) => {
			StockfishInterface.topMovesFinalCallback.add((stockfishMoves) => {
				resolve(stockfishMoves);
			});
		});
		return promise;
	},
	setPosition: (epd: string) => {
		StockfishInterface.uciCmd(`position fen ${epd}`);
	},
	epd: null as string | null,
	go: async (
		epd: string,
		opts: {
			nodes?: number;
		},
	): Promise<StockfishEval> => {
		StockfishInterface.running = false;
		const position = new Chess(Epd.toFen(epd));
		const moves = position.moves({ verbose: true });
		moves.forEach((move) => {
			StockfishInterface.legalMoves[moveToLan(move)] = move.san;
		});
		// console.debug("[Stockfish] legal moves", Stockfish.legalMoves);
		StockfishInterface.position = position;
		StockfishInterface.epd = epd;
		StockfishInterface.uciCmd(`go nodes ${opts.nodes || EVAL_NODES}`);
		StockfishInterface.nodeGoal = opts.nodes ?? EVAL_NODES.STANDARD;
		StockfishInterface.uciCmd(`position fen ${epd}`);
		const promise = new Promise<StockfishEval>((resolve) => {
			StockfishInterface.finalEvalCallback.add((stockfishEval) => {
				StockfishInterface.running = false;
				resolve(stockfishEval);
			});
		});
		return promise;
	},
	evalPos: async ({
		epd,
		nodes,
		cb,
	}: {
		epd: string;
		nodes?: number;
		cb?: (res: StockfishEval) => void;
	}): Promise<StockfishEval | null> => {
		if (!STOCKFISH_LOADED) {
			return null;
		}
		StockfishInterface.cancel();
		StockfishInterface.finalEvalCallback.clear();
		StockfishInterface.evalUpdatedCallback.clear();
		const nodesValue = nodes ?? EVAL_NODES.STANDARD;

		const cacheKey = toEvalCacheKey(epd, nodesValue);
		if (EVAL_CACHE[cacheKey]) {
			return EVAL_CACHE[cacheKey];
		}

		StockfishInterface.setMultiPv(1);
		let fen = Epd.toFen(epd);
		StockfishInterface.setPosition(fen);
		let positiion = new Chess(fen);
		StockfishInterface.position = positiion;
		StockfishInterface.uciCmd(`go nodes ${nodesValue}`);
		StockfishInterface.nodeGoal = nodesValue;
		if (cb) {
			StockfishInterface.evalUpdatedCallback.add(cb);
		}
		const start = performance.now();
		const promise = new Promise<StockfishEval>((resolve) => {
			StockfishInterface.finalEvalCallback.add((stockfishEval) => {
				console.debug(`Stockfish eval for ${nodesValue} took ${performance.now() - start}ms`);
				EVAL_CACHE[cacheKey] = stockfishEval;
				resolve(stockfishEval);
			});
		});
		return promise;
	},
	ready: () => {
		console.debug("stockfish ready!");
		StockfishInterface.isReady = true;
		StockfishInterface.readyCallback.call();
	},
	onBestMove: (_uci: string) => {
		if (StockfishInterface.lastInfo) {
			// console.log("saying we found the best move!");
			StockfishInterface.onInfo(StockfishInterface.lastInfo!, true);
		}
	},
	onReady: (): Promise<void> => {
		return new Promise<void>((resolve) => {
			StockfishInterface.readyCallback.add(() => {
				resolve();
			});
		});
	},
	lastInfo: null as StockfishInfo | null,
	pendingMoves: [] as StockfishMove[],
	cancel: () => {
		StockfishInterface.uciCmd("stop");
		StockfishInterface.finalEvalCallback.clear();
		StockfishInterface.evalUpdatedCallback.clear();
		StockfishInterface.topMovesFinalCallback.clear();
		StockfishInterface.topMovesUpdatedCallback.clear();
		StockfishInterface.pendingMoves = [];
		StockfishInterface.lastInfo = null;
		StockfishInterface.running = false;
		StockfishInterface.epd = null;
	},
	onInfo: (info: StockfishInfo, final = false) => {
		const { stockfishEval, multipv, pvLan } = info;
		if (final) {
			stockfishEval.thinking = false;
		}
		StockfishInterface.evalUpdatedCallback.call(stockfishEval);
		if (final) {
			StockfishInterface.finalEvalCallback.call(stockfishEval);
		}
		StockfishInterface.lastInfo = info;
		// console.debug("parsed info", info, Stockfish.legalMoves);
		const stockfishMove: StockfishMove = {
			stockfishEval,
			san: StockfishInterface.legalMoves[pvLan]!,
		};
		// console.debug("stockfishMove", stockfishMove);
		if (multipv === 1) {
			StockfishInterface.pendingMoves = [];
		}
		const legalMoves = StockfishInterface.legalMoves;
		StockfishInterface.pendingMoves.push(stockfishMove);
		if (
			multipv === StockfishInterface.multiPvSetting ||
			multipv === Object.keys(legalMoves).length
		) {
			if (final) {
				StockfishInterface.pendingMoves = StockfishInterface.pendingMoves.map((sm) => {
					sm.stockfishEval.thinking = false;
					return { ...sm, final: true };
				});
			}
			StockfishInterface.topMovesUpdatedCallback.call(StockfishInterface.pendingMoves);
			if (final) {
				StockfishInterface.topMovesFinalCallback.call(StockfishInterface.pendingMoves);
			}
		}
	},
};

type StockfishInfo = {
	stockfishEval: StockfishEval;
	multipv: number;
	pvLan: string;
	nodes?: number;
	pv?: string;
};

const handleUciMessage = (msg: any) => {
	// console.debug("handleUciMessage", msg);
	if (msg === "uciok") {
		StockfishInterface.loaded = true;
		StockfishInterface.loadedCallback.call();
		StockfishInterface.uciCmd("isready");
		return;
	}
	if (msg === "readyok") {
		StockfishInterface.isReady = true;
		StockfishInterface.ready();
		return;
	}
	let match = msg.match(/^bestmove ([a-h][1-8][a-h][1-8][qrbn]?)/);
	/// Did the AI move?
	if (match) {
		StockfishInterface.running = false;
		StockfishInterface.onBestMove(match[1]);
		// game.move({ from: match[1], to: match[2], promotion: match[3] });
		// prepareMove();
		// uciCmd("eval", evaler);
		// evaluation_el.textContent = "";
		//uciCmd("eval");
		/// Is it sending feedback?
	} else {
		match = msg.match(/^info .*\bdepth (\d+) .*\bnps (\d+)/);
		// engineStatus.search = "Depth: " + match[1] + " Nps: " + match[2];
	}

	match =
		/^info .*\b multipv (?<multipv>\d+).*score (?<evalType>\w+).* (?<score>-?\d+).* nodes (?<nodes>\d+) .* pv (?<pv>\w+)/.exec(
			msg,
		);
	if (match) {
		const matches: Record<string, string> = match.groups;
		let score = Number.parseInt(matches.score);
		if (StockfishInterface.position?.turn() === "b") {
			score = -score;
		}
		const stockfishEval = new StockfishEval("0");
		stockfishEval.thinking = true;
		/// Is it measuring in centipawns?
		if (matches.evalType === "cp") {
			stockfishEval.eval = score;
		} else if (matches.evalType === "mate") {
			stockfishEval.eval = undefined;
			stockfishEval.mate = [
				score,
				score === 0
					? Side.fromColor(StockfishInterface.position?.turn() ?? "w")
					: score < 0
						? "black"
						: "white",
			];
		}
		let pv = matches.pv;
		stockfishEval.pv = pv;

		StockfishInterface.onInfo({
			stockfishEval,
			nodes: matches.nodes ? Number.parseInt(matches.nodes) : undefined,
			multipv: Number.parseInt(matches.multipv),
			pvLan: matches.pv,
			pv: pv,
		});
	}
};

const _sortaTest = async () => {
	console.debug("TESTING STOCKFISH");
	// await Stockfish.onReady();
	console.debug("Stockfish ready!");
	console.debug("Evaluating move!");
	const finalEval = await StockfishInterface.evalPos({
		// epd: START_EPD,
		// mate
		// epd: "rnbqkbnr/p2pp2P/1pp5/8/8/8/PPPP1PPP/RNBQKBNR w KQkq -",
		// white winning
		// epd: "rnbqkbnr/pp1pp2p/2p3P1/8/8/8/PPPP1PPP/RNBQKBNR w KQkq -",
		// slight winning
		epd: "r1bqkbnr/ppp2ppp/2np4/1B2p3/4P3/5N2/PPPP1PPP/RNBQK2R w KQkq -",
		nodes: 1000000,
		cb: (res) => {
			console.debug("eval move res:", res);
		},
	});
	console.debug("Final eval:", finalEval);
	// console.debug("------ TOP MOVES ------");
	// const moves = await Stockfish.getTopMoves(START_EPD, 100, (moves) => {
	// 	console.debug("moves in progress", moves);
	// });
	// console.debug("final moves", moves);
};
