import { BASE_FRONTEND_URL } from "./base_url";
import { isNative } from "./env";

export const getStatic = (path: string) => {
	if (BASE_FRONTEND_URL) {
		return `${BASE_FRONTEND_URL}${path}`;
	}
	return path;
	// evenually
	// return `${path}?ver=${process.env.FRONTEND_VERSION}`;
};

export const getStaticLocal = (path: string) => {
	if (isNative) {
		return path;
	}
	return getStatic(path);
	// evenually
	// return `${path}?ver=${process.env.FRONTEND_VERSION}`;
};
