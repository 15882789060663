import { Show, createEffect, createSignal, onMount } from "solid-js";
import type { CourseDetailsDTO, CourseOverviewDTO } from "~/rspc";
import { BROWSING_STATE, UI, quick } from "~/utils/app_state";
import { rspcClient } from "~/utils/rspc_client";
import { onBack } from "~/utils/signals/onBack";
import { trackEvent } from "~/utils/trackEvent";
import { CourseBrowser } from "./CourseBrowser";
import { CourseTitle } from "./CourseTitle";
import { OfficalRepertoireTag } from "./OfficialRepertoireTag";
import { SidebarActions } from "./SidebarActions";
import { Spacer } from "./Space";

export const CourseView = (props: { course: CourseOverviewDTO }) => {
	const [courseDetails, setCourseDetails] = createSignal(null as CourseDetailsDTO | null);

	const _loading = () => courseDetails() === null;
	createEffect(() => {
		quick((s) => {
			s.repertoireState.browsingState.activeCourse.overview = props.course;
			if (courseDetails()) {
				s.repertoireState.browsingState.activeCourse.details = courseDetails()!;
			}
		});
	});
	onBack(() => {
		BROWSING_STATE().activeCourse.overview = undefined;
		BROWSING_STATE().activeCourse.details = undefined;
	});
	onMount(() => {
		trackEvent("courses.courseViewed", {
			courseId: props.course.id,
			courseName: props.course.name,
		});
		rspcClient
			.query([
				"openings.courses.detailsById",
				{ id: props.course.id, repertoireId: BROWSING_STATE().getActiveRepertoire()!.id! },
			])
			.then((data) => {
				setCourseDetails(data);
			});
	});
	const currentEpd = () => BROWSING_STATE().chessboard.getCurrentEpd();
	return (
		<div class="">
			<div class="padding-sidebar">
				<CourseTitle course={props.course} />
				<Spacer between={["header", "body-text"]} />
				{props.course.videoId && false && (
					<div>
						<div style="position:relative;padding-top:56.25%;">
							<iframe
								title="Course video"
								src={`https://iframe.mediadelivery.net/embed/256978/${props.course.videoId}?autoplay=false&loop=false&muted=false&preload=true&responsive=true`}
								loading="lazy"
								style="border:0;position:absolute;top:0;height:100%;width:100%;"
								allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;cross-origin-isolated"
								allowfullscreen={true}
							></iframe>
						</div>
						<Spacer height={24} />
					</div>
				)}
				{props.course.description && (
					<div>
						<p class="whitespace-pre-wrap body-text">{props.course.description}</p>
					</div>
				)}
				<Show when={props.course.official}>
					<Spacer height={24} />
					<OfficalRepertoireTag />
				</Show>
				<Spacer height={24} />
			</div>
			<Show when={courseDetails()}>
				<SidebarActions
					actions={[
						{
							onPress: () => {
								trackEvent("courses.importEntireCourse.clicked", {
									courseId: props.course.id,
									courseName: props.course.name,
								});
								BROWSING_STATE().requestCourseImport({
									course: props.course,
									courseDetails: courseDetails()!,
									fromEpd: currentEpd(),
								});
							},
							text: "Add to your repertoire",
							// right: pluralize(
							// 	Course.countMovesFrom(courseDetails()!.responses, START_EPD, undefined).totalMoves,
							// 	"move",
							// ),
							style: "primary",
						},
						{
							onPress: () => {
								trackEvent("courses.browseCourse.clicked", {
									courseId: props.course.id,
									courseName: props.course.name,
								});
								let epd = currentEpd();
								UI().pushView(CourseBrowser, {
									props: {
										course: props.course,
										courseDetails: courseDetails()!,
										originalEpd: epd,
									},
								});
							},
							text: "Browse before adding",
							style: "secondary",
						},
					]}
				/>
			</Show>
		</div>
	);
};
