import { type ComponentProps, For, Show, createEffect, onMount } from "solid-js";
import { BLUNDER_PUZZLES_STATE, UI, USER_STATE, quick } from "~/utils/app_state";

import { Browser } from "@capacitor/browser";
import { destructure } from "@solid-primitives/destructure";
import { chunk } from "lodash-es";
import { clsx } from "~/utils/classes";
import { onBack } from "~/utils/signals/onBack";
import { createSharedTrainerSettingActions } from "~/utils/trainer_settings";
import { Bullet } from "./Bullet";
import type { ChessboardArrowView } from "./ChessboardArrow";
import { type SidebarAction, SidebarActions, SidebarSectionHeader } from "./SidebarActions";
import { SidebarTemplate } from "./SidebarTemplate";
import { Spacer } from "./Space";

export const BlunderPuzzleTraining = () => {
	onMount(() => {
		if (BLUNDER_PUZZLES_STATE().onboarded.value === false) {
			UI().pushViewNoAnimation(BlunderPuzzleTrainingOnboarding);
		}
	});
	createEffect(() => {
		if (BLUNDER_PUZZLES_STATE().puzzleStage === "loading" && USER_STATE().user) {
			quick((s) => {
				s.trainersState.blunderPuzzlesState.initState();
				s.userState.fetchPuzzleStats();
			});
		}
	});

	const mode = () => BLUNDER_PUZZLES_STATE().getMode();
	const [topActions, bottomActions] = destructure(() => {
		const topActions: SidebarAction[] = [];
		const bottomActions: SidebarAction[] = [];
		const analyzeOnLichessAction = {
			text: "Analyze on Lichess",
			onPress: () => {
				Browser.open({ url: currentPuzzle()!.link });
			},
			style: "primary",
		} as SidebarAction;
		if (BLUNDER_PUZZLES_STATE().getMode() === "standard") {
			if (BLUNDER_PUZZLES_STATE().puzzleStage === "complete") {
				topActions.push(analyzeOnLichessAction);
				topActions.push({
					text: "Next puzzle",
					onPress: () => BLUNDER_PUZZLES_STATE().standardState.setupNextPuzzle(),
					style: "focus",
				});
			} else {
				bottomActions.push({
					text: "Give up",
					onPress: () => BLUNDER_PUZZLES_STATE().standardState.giveUp(),
					style: "primary",
				});
			}
		}
		if (BLUNDER_PUZZLES_STATE().getMode() === "streak") {
			if (BLUNDER_PUZZLES_STATE().streakState.streakStatus === "broken") {
				bottomActions.push({
					text: "New streak",
					onPress: () => BLUNDER_PUZZLES_STATE().streakState.refresh(),
					style: "focus",
				});
				bottomActions.push(analyzeOnLichessAction);
			}
		}
		return [topActions, bottomActions];
	});
	const userPuzzleStats = () => USER_STATE().puzzleStats;
	const currentPuzzle = () => BLUNDER_PUZZLES_STATE().getCurrentPuzzle();
	const { statRows } = destructure(() => {
		const stats: Stat[] = [];
		const _puzzleStage = BLUNDER_PUZZLES_STATE().puzzleStage;
		if (BLUNDER_PUZZLES_STATE().getMode() === "streak") {
		}
		if (BLUNDER_PUZZLES_STATE().getMode() === "standard") {
			stats.push({
				label: "Your rating",
				value: userPuzzleStats()?.blunderPuzzlesGlicko.toFixed(0) as string,
			});
		}
		if (currentPuzzle() && mode() !== "streak") {
			stats.push({ label: "Puzzle rating", value: currentPuzzle()!.glicko.toFixed(0) });
		}
		let completedStats: Stat[] = [];
		// if (puzzleStage === "complete") {
		// 	completedStats.push({
		// 		label: "Original blunder",
		// 		value: currentPuzzle()!.originalBlunderSan,
		// 	});
		// 	completedStats.push({
		// 		label: "Acceptable moves",
		// 		value: `
		// 			${
		// 				// @ts-ignore
		// 				currentPuzzle()!.sansByMoveRating.good.length +
		// 				// @ts-ignore
		// 				currentPuzzle()!.sansByMoveRating.inaccuracy.length
		// 			} out of ${sumBy(values(currentPuzzle()!.sansByMoveRating), "length")}`,
		// 	});
		// 	completedStats.push({
		// 		label: "Position Evaluation",
		// 		value: `${formatStockfishEval(new StockfishEval(currentPuzzle()!.eval))}`,
		// 	});
		// }
		return { statRows: [stats, completedStats] };
	});

	const _arrows = () => {
		let _arrows: ComponentProps<typeof ChessboardArrowView>[] = [];
		if (BLUNDER_PUZZLES_STATE().puzzleSuccess === "gave_up") {
			return [];
		}
		return [];
	};
	const streak = () => BLUNDER_PUZZLES_STATE().streakState.currentStreak;

	return (
		<>
			<SidebarTemplate header={null} actions={[]}>
				<Show when={mode() === "streak" && currentPuzzle()}>
					<div class="flex row justify-center padding-sidebar items-center">
						<div class="col space-y-1  justify-center items-center py-4 md:py-7">
							<p class="text-primary text-3xl font-semibold items-center flex">
								{BLUNDER_PUZZLES_STATE().streakState.currentStreak}
								<i
									class={clsx(
										"fas fa-fire  pl-2 text-lg",
										streak() >= 10
											? "text-red-60"
											: streak() >= 5
												? "text-yellow-50"
												: streak() > 0
													? "text-blue-50"
													: "text-tertiary",
									)}
								/>
							</p>
						</div>
					</div>
				</Show>
				<Show when={topActions().length > 0}>
					<SidebarActions actions={topActions()} />
					<Spacer between={["actions", "table"]} />
				</Show>
				<Show when={statRows()[0].length > 0 || statRows()[1].length > 0}>
					<div class="col space-y-8">
						<For each={statRows()}>{(row) => <Stats stats={row} />}</For>
					</div>
				</Show>
				<Show when={bottomActions().length > 0}>
					<Spacer between={["table", "actions"]} />
					<SidebarActions actions={bottomActions()} />
				</Show>
				<Spacer height={72} />
				<SidebarSectionHeader text="Settings" />
				<SidebarActions actions={[...createSharedTrainerSettingActions()]} />
			</SidebarTemplate>
		</>
	);
};

type Stat = {
	label: string;
	value: string;
	align?: "left" | "right";
};

export const Stats = (props: { stats: Stat[] }) => {
	let rows = () => {
		return chunk(props.stats, 3);
	};
	return (
		<div class="padding-sidebar col gap-2">
			<For each={rows()}>
				{(row) => (
					<div class={clsx("flex row justify-start space-x-4")}>
						<For each={row}>{(stat) => <StatView stat={stat} />}</For>
					</div>
				)}
			</For>
		</div>
	);
};

export const StatView = (props: { stat: Stat }) => {
	return (
		<div class={clsx("flex min-w-[140px]", props.stat.align === "right" && "items-end")}>
			<div class="text-tertiary text-sm font-medium pb-2">{props.stat.label}</div>
			<div class="text-primary text-base font-medium">{props.stat.value}</div>
		</div>
	);
};

export const BlunderPuzzleTrainingOnboarding = () => {
	const bullets = [
		"There will be many valid moves in every position.",
		"Puzzles will get progressively harder.",
	];
	onBack(() => {}, "hide");
	return (
		<SidebarTemplate
			header="Welcome to Blunderbash!"
			bodyPadding={true}
			actions={[
				{
					onPress: () => {
						quick((_s) => {
							BLUNDER_PUZZLES_STATE().onboarded.value = true;
							UI().popView({ direction: "right" });
						});
					},
					style: "focus",
					text: "Got it",
				},
			]}
		>
			<p class={"body-text"}>
				You'll be presented with a position, all you have to do is not blunder.
			</p>
			<Spacer between={["body-text", "bullets"]} />
			<div class="gap-2 pl-6">
				{bullets.map((bullet) => (
					<Bullet>{bullet}</Bullet>
				))}
			</div>
			<Spacer between={["body-text", "body-text"]} />
			<p class="body-text">Don't blunder!</p>
		</SidebarTemplate>
	);
};
