import { memoize } from "lodash-es";
import { isServer } from "solid-js/web";

export type Feature = "wasm" | "sharedMem" | "simd" | "dynamicImportFromWorker";

export const hasDeviceFeature = (feat?: string): boolean =>
	!feat || getFeatures().includes(feat as Feature);

function sharedMemoryTest(): boolean {
	if (typeof Atomics !== "object") return false;
	if (typeof SharedArrayBuffer !== "function") return false;

	let mem: WebAssembly.Memory;
	try {
		mem = new WebAssembly.Memory({ shared: true, initial: 1, maximum: 2 });
		if (!(mem.buffer instanceof SharedArrayBuffer)) return false;

		window.postMessage(mem.buffer, "*");
	} catch {
		return false;
	}
	return mem.buffer instanceof SharedArrayBuffer;
}

export const getFeatures: () => readonly Feature[] = memoize(() => {
	const features: Feature[] = [];
	if (
		typeof WebAssembly === "object" &&
		typeof WebAssembly.validate === "function" &&
		WebAssembly.validate(Uint8Array.from([0, 97, 115, 109, 1, 0, 0, 0]))
	) {
		features.push("wasm");
		// i32x4.dot_i16x8_s, i32x4.trunc_sat_f64x2_u_zero
		const sourceWithSimd = Uint8Array.from([
			0, 97, 115, 109, 1, 0, 0, 0, 1, 12, 2, 96, 2, 123, 123, 1, 123, 96, 1, 123, 1, 123, 3, 3, 2,
			0, 1, 7, 9, 2, 1, 97, 0, 0, 1, 98, 0, 1, 10, 19, 2, 9, 0, 32, 0, 32, 1, 253, 186, 1, 11, 7, 0,
			32, 0, 253, 253, 1, 11,
		]);
		if (WebAssembly.validate(sourceWithSimd)) features.push("simd");
		if (sharedMemoryTest()) features.push("sharedMem");
	}
	try {
		new Worker(
			URL.createObjectURL(
				new Blob(["import('data:text/javascript,export default {}')"], {
					type: "application/javascript",
				}),
			),
		).terminate();
		features.push("dynamicImportFromWorker");
	} catch {}
	return Object.freeze(features);
});

if (!isServer) {
	console.debug("Features", getFeatures());
}
