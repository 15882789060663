import { Suspense, lazy } from "solid-js";
import { APP_STORE_LINK, PLAY_STORE_LINK } from "~/constants";
import { getStatic } from "~/utils/assets";
import { trackEvent } from "~/utils/trackEvent";
import { Spacer } from "./Space";
import { ToastPromotion, ToastPromotionType } from "./ToastPromotion";
const QRCode = lazy(() => import("./QRCode"));

export const MobileAppPromotion = () => {
	return (
		<ToastPromotion flag={ToastPromotionType.MobileApps}>
			<h1 class="text-lg text-primary font-semibold pt-8">Get the Chessbook app!</h1>
			<Spacer height={36} />
			<div class="flex flex-row items-center justify-center gap-2 shrink-1 min-h-0 relative grow-1">
				<div class="absolute right-[calc(40%+12px)] h-full">
					<div class="h-full aspect-[1/2]">
						<img
							src={getStatic("/app_promo/screenshot.svg")}
							class="h-full w-full object-contain"
						/>
					</div>
				</div>
				<div class="absolute left-[calc(60%+12px)] flex flex-col items-center">
					<div class="w-[68px] h-[68px] rounded-sm overflow-hidden">
						<Suspense fallback={<div class="bg-white h-full w-full" />}>
							<QRCode value={`chessbook.com/qr`} />
						</Suspense>
					</div>
					<span class="text-base text-primary pt-3 font-semibold">Scan me</span>
				</div>
			</div>
			<Spacer height={36} />
			<div class="row">
				<a
					href={APP_STORE_LINK}
					use:onClick={() => {
						trackEvent("app_promotion.clicked", { store: "app_store" });
					}}
					target="_blank"
					rel="noopener noreferrer"
					class="h-[30px] grow"
				>
					<img class=" h-full w-full" src={getStatic("/app_promo/app_store.svg")} />
				</a>
				<Spacer width={32} />
				<a
					href={PLAY_STORE_LINK}
					use:onClick={() => {
						trackEvent("app_promotion.clicked", { store: "play_store" });
					}}
					target="_blank"
					rel="noopener noreferrer"
					class="h-[30px] grow"
				>
					<img class=" grow h-full w-full" src={getStatic("/app_promo/play_store.svg")} />
				</a>
			</div>
		</ToastPromotion>
	);
};
