import { Chess } from "@lubert/chess.ts";
import { forEach } from "lodash-es";
import { genEpd } from "~/utils/chess";
import type { Side } from "./Side";

export type Line = string[];

export namespace Line {
	export function sideOfLastMove(_line: Line | string): Side {
		let line = _line;
		if (typeof _line === "string") {
			line = _line.split(" ");
		}
		if (line.length % 2 === 1) {
			return "white";
		}
		return "black";
	}

	export function toPgn(line: Line): string {
		let pgn = "";
		forEach(line, (m, j) => {
			const i = j / 2 + 1;
			if (j % 2 === 1) {
				pgn = `${pgn} ${m}`;
			} else {
				pgn = `${pgn} ${i}.${m}`;
			}
		});

		return pgn.trim();
	}

	export function fromPgn(line: string): Line {
		return line
			.replaceAll(/\d+\./g, "")
			.split(" ")
			.filter((x) => x !== "");
	}

	export function getMoveNumber(line: Line) {
		return Math.floor(line.length / 2 + 1);
	}

	const lineToEpdsLookup = new Map<string, string[]>();

	export function toPositions(pgn: string): string[] {
		if (lineToEpdsLookup.has(pgn)) {
			return lineToEpdsLookup.get(pgn)!;
		}
		let chessPosition = new Chess();
		let positions: string[] = [];
		let line = Line.fromPgn(pgn);
		for (let move of line) {
			chessPosition.move(move);
			positions.push(genEpd(chessPosition));
		}
		lineToEpdsLookup.set(pgn, positions);
		return positions;
	}
}
