import { Chess, type Move } from "@lubert/chess.ts";
import type { Square } from "@lubert/chess.ts/dist/types";
import { find, map } from "lodash-es";
import {
	type Accessor,
	For,
	type JSX,
	Show,
	createEffect,
	createMemo,
	createSignal,
} from "solid-js";
import { Portal } from "solid-js/web";
import { TransitionGroup } from "solid-transition-group";

import type { EnrichedComponent } from "~/types/View";
import { APP_STATE, CARDS_REVIEW_STATE, CARDS_STATE, UI, quick, useMode } from "~/utils/app_state";

import { clsx } from "~/utils/classes";
import { isDevelopment } from "~/utils/env";
import { isMobile } from "~/utils/isMobile";

import { registerViewMode } from "~/utils/register_view_mode";

import { onBack } from "~/utils/signals/onBack";
import { onKeyPress } from "~/utils/signals/onKeyPress";
import { c } from "~/utils/styles";
import { COMBINED_THEMES_BY_ID, type CombinedTheme, combinedThemes } from "~/utils/theming";
import { trackEvent } from "~/utils/trackEvent";
import { ChessboardArrowView } from "./ChessboardArrow";
import { SidebarHeader } from "./RepertoireEditingHeader";

import { type SidebarAction, SidebarActions } from "./SidebarActions";

import { QuizPositionStage } from "~/utils/cards_review_state";
import { type AnimateSidebar, SidebarSlideContainer } from "./SidebarSlideContainer";
import { SidebarTemplate } from "./SidebarTemplate";

export const CardReview: EnrichedComponent = () => {
	createEffect(() => {
		if (CARDS_REVIEW_STATE().previousQuizPosition) {
			onBack(() => {
				quick((s) => {
					animateBottomSidebarSection()?.("left");
					s.cardsState.reviewState.backToLastQuizPosition();
				});
			}, "stay");
		}
	});
	const currentCard = () => CARDS_REVIEW_STATE().currentQuizPosition;
	const stage = () => CARDS_REVIEW_STATE().quizPositionStage;
	onKeyPress((key) => {
		if (!isDevelopment) {
			return;
		}
		if (key === "a") {
			quick((s) => {
				const correctNextMove = s.repertoireState.reviewState.getNextReviewPositionMove();
				if (!correctNextMove) {
					return;
				}
				const move = s.repertoireState.reviewState.chessboard
					.get((c) => c.position)
					.validateMoves([correctNextMove.sanPlus])![0]!;
				s.repertoireState.reviewState.chessboard.requestToMakeMove(move);
			});
		}
	});

	const mode = useMode();

	const reviewStats = () => CARDS_REVIEW_STATE().reviewStats;
	const progressIcons = () => {
		return [
			{
				icon: "fa fa-clock",
				class: "text-yellow-60",
				text: `${reviewStats().due + 1} Due`,
			},
			{
				icon: "fa-kit fa-tick",
				class: "text-inverse bg-green-60 rounded-full",
				text: reviewStats().correct,
			},
			{
				icon: "fa-kit fa-cross",
				class: "text-inverse bg-red-60 rounded-full",
				text: reviewStats().incorrect,
			},
		];
	};
	const actions: Accessor<(SidebarAction & { hidden?: boolean })[]> = () => {
		let card = currentCard()?.card;
		if (!card) {
			return [];
		}
		const actions: SidebarAction[] = [];
		// const inspectLineAction: SidebarAction = {
		// 	onPress: () => {
		// 		quick((s) => {
		// 			trackEvent(`${mode()}.inspect_line`);
		// 			s.repertoireState.reviewState.inspectCurrentLine();
		// 		});
		// 	},
		// 	style: "primary",
		// 	text: "Exit practice and view in repertoire builder",
		// };
		if (stage() === QuizPositionStage.Full) {
			if (card.srs.firstReview) {
				actions.push({
					onPress: () => {
						quick((_s) => {
							CARDS_REVIEW_STATE().setupNextQuizPosition();
						});
					},
					style: "primary",
					text: "Keep this position for future review",
				});
				actions.push({
					onPress: () => {
						quick((_s) => {
							CARDS_STATE().deleteCard(card);
							CARDS_REVIEW_STATE().setupNextQuizPosition();
						});
					},
					style: "primary",
					text: "I don't need to see this card again",
				});
			} else {
				actions.push({
					onPress: () => {
						quick((s) => {
							animateBottomSidebarSection()?.("right");
							s.cardsState.reviewState.setupNextQuizPosition();
						});
					},
					style: "focus",
					text: "Got it, continue practicing",
				});
			}
			// if (!onboarding().isOnboarding) {
			// 	// actions.push(inspectLineAction);
			// }
		} else if (stage() !== QuizPositionStage.Arrows) {
			actions.push({
				onPress: () => {
					quick((_s) => {
						trackEvent(`${mode()}.give_up`);
						CARDS_REVIEW_STATE().giveUp();
					});
				},
				style: "primary",
				text: "I don't know, show me the answer",
			});
		}

		return actions;
	};
	const userState = APP_STATE().userState;
	const user = () => userState.user;
	const _combinedTheme: Accessor<CombinedTheme> = createMemo(
		() =>
			find(combinedThemes, (theme) => theme.boardTheme === user()?.theme) ||
			COMBINED_THEMES_BY_ID.default,
	);

	const body: Accessor<JSX.Element> = () => {
		let card = currentCard()?.card;
		if (!card) {
			return "TODO";
		}
		if (!card.winChanceAfter) {
			return "Find a good move in this position.";
		}

		let descriptionBefore = gradePositionVerbally(card.winChance);
		let descriptionAfter = gradePositionVerbally(card.winChanceAfter);

		return (
			<>
				You took this position from{" "}
				<span class={`text-${descriptionBefore[1]} font-semibold`}>{descriptionBefore[0]}</span> to{" "}
				<span class={`text-${descriptionAfter[1]} font-semibold`}>{descriptionAfter[0]}</span>. Find
				a better move.
			</>
		);
	};
	// const {} = useHovering();
	const arrowMoves = () => {
		const animatingPosition = CARDS_REVIEW_STATE().chessboard.getIsAnimating();
		if (animatingPosition) {
			return [];
		}
		const moves = currentCard()?.card.bestMoves?.filter((_m) => {
			if (stage() === QuizPositionStage.Arrows) {
				return true;
			}
			return false;
		});
		if (!moves || moves.length === 0) {
			return [];
		}
		const fen = `${currentCard()!.card.epd} 0 1`;
		const position = new Chess(fen);
		const moveObjects = moves.map((m) => {
			return position.validateMoves([m])![0];
		});
		return map(moveObjects, (m: Move) => {
			return {
				move: m,
				played: false,
			};
		});
	};
	// const mistakeMove = () => {
	// 	const lichessMistake = currentMove()?.lichessMistake;
	// 	if (!lichessMistake) {
	// 		return null;
	// 	}
	// 	const fen = `${lichessMistake.epd} 0 1`;
	// 	const position = new Chess(fen);
	// 	const [move] = position.validateMoves([lichessMistake.playedSan]) as [Move];
	// 	return move;
	// };

	const arrowsUnderneathMountPoint = CARDS_REVIEW_STATE().chessboard.get(
		(s) => s.refs.arrowsUnderneathContainerRef,
	);
	const flipped = () => APP_STATE().cardsState.reviewState.chessboard.get((s) => s.flipped);
	const [animateBottomSidebarSection, setAnimateBottomSidebarSection] =
		createSignal<AnimateSidebar | null>(null);
	const dragging = () => CARDS_REVIEW_STATE().chessboard.get((s) => !!s.drag.square);
	return (
		<>
			<Portal mount={arrowsUnderneathMountPoint ?? undefined}>
				<TransitionGroup
					onEnter={(el, done) => {
						const a = el.animate([{ opacity: 0 }, { opacity: 1 }], {
							duration: 200,
						});
						a.finished.then(done);
					}}
					onExit={(el, done) => {
						const a = el.animate([{ opacity: 1 }, { opacity: 0 }], {
							duration: 200,
						});
						a.finished.then(done);
					}}
				>
					<Show when={!dragging() && UI().currentView()?.component === CardReview}>
						<For each={arrowMoves()}>
							{(learningMove) => (
								<div>
									<ChessboardArrowView
										focused={false}
										faded={!!dragging() || learningMove.played}
										color={c.orange[55]}
										flipped={flipped()}
										toSquare={learningMove.move.to as Square}
										fromSquare={learningMove.move.from as Square}
									/>
								</div>
							)}
						</For>
					</Show>
				</TransitionGroup>
			</Portal>
			<SidebarTemplate header={null} actions={[]}>
				<div class={"row w-full items-baseline justify-between padding-sidebar"}>
					<SidebarHeader>{isMobile() ? "Reviewing" : `Reviewing cards`}</SidebarHeader>
					<div class="row items-center space-x-4 lg:space-x-8">
						<For each={progressIcons()}>
							{(i) => {
								return (
									<div class="row items-center">
										<p class={clsx("text-sm font-semibold lg:text-base text-secondary")}>
											{i.text}
										</p>
										<i class={clsx(i.class, i.icon, " ml-2 text-sm lg:text-base")} />
									</div>
								);
							}}
						</For>
					</div>
				</div>
				<div class={"h-4 lg:h-10"} />
				<SidebarSlideContainer
					setAnimateSidebar={(fn) => setAnimateBottomSidebarSection(() => fn)}
					class={"space-y-4 lg:space-y-6"}
				>
					<Show when={body()}>
						<p class="body-text leading-5 padding-sidebar">{body()}</p>
					</Show>
					<SidebarActions actions={actions()} />
				</SidebarSlideContainer>
			</SidebarTemplate>
		</>
	);
};

registerViewMode(CardReview, "review");

function gradePositionVerbally(winChance: number): [string, string] {
	if (winChance >= 0.9) {
		return ["totally winning", "green-70"];
	}
	if (winChance >= 0.7) {
		return ["somewhat winning", "green-70"];
	}
	if (winChance > 0.55) {
		return ["slightly winning", "gray-95"];
	}
	if (winChance >= 0.45) {
		return ["even", "gray-95"];
	}
	if (winChance >= 0.3) {
		return ["somewhat lost", "yellow-70"];
	}
	if (winChance >= 0.1) {
		return ["clearly lost", "red-70"];
	}
	return ["totally lost", "red-70"];
}
