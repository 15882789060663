import { type JSX, Show } from "solid-js";
import { UI } from "~/utils/app_state";
import { c, stylex } from "~/utils/styles";
import { useResponsiveV2 } from "~/utils/useResponsive";
import { VERTICAL_BREAKPOINT } from "./SidebarLayout";
import { type AnimateSidebar, SidebarSlideContainer } from "./SidebarSlideContainer";

export let _animateSidebar: AnimateSidebar | null = null;
export const animateSidebar = (dir: "left" | "right") => {
	UI().animateSidebar(dir);
};

export const SidebarContainer = (props: {
	settings: JSX.Element;
	children: JSX.Element;
	backSection: JSX.Element;
}) => {
	const responsive = useResponsiveV2();
	const vertical = () => responsive().bp < VERTICAL_BREAKPOINT;

	return (
		<div
			style={stylex(
				c.column,
				c.zIndex(4),
				c.relative,
				c.overflowHidden,
				c.bg(c.gray[14]),
				c.minHeight("100%"),
			)}
			class="pb-5 lg:pb-12"
		>
			<Show when={!vertical()}>
				<div
					style={stylex(
						c.absolute,
						c.top(0),
						c.right(0),
						c.zIndex(15),
						c.pr(c.getSidebarPadding(responsive())),
						c.pt(c.getSidebarPadding(responsive())),
					)}
				>
					{props.settings}
				</div>
			</Show>
			{!vertical() && props.backSection}
			<SidebarSlideContainer
				setAnimateSidebar={(animateSidebar) => {
					_animateSidebar = animateSidebar;
				}}
			>
				<Show when={vertical()}>{props.backSection}</Show>
				{props.children}
			</SidebarSlideContainer>
		</div>
	);
};
